import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GRID_PROPERTY } from 'consts';
import merge from 'deepmerge';
import { globalFilterReset } from './gridFilterForm/slice';
import { getGridName } from 'module/grid/helper';
import { getFixedGridSize } from 'views/dashboard/utils/useDashboard';
import { floorObject } from 'helpers/utils';
import _ from 'lodash';

const defaultGridSize = {
  typeOne: {
    left: {
      width: '2',
    },
    right: {
      width: '1',
    },
  },
  typeTwo: {
    left: {
      width: '2',
      top: { height: '2' },
      bottom: { height: '1' },
    },
    right: {
      width: '1',
    },
  },
  typeThree: {
    left: {
      width: '1',
      top: { height: '1' },
      bottom: { height: '1' },
    },
    right: {
      width: '1',
      top: { height: '1' },
      bottom: { height: '1' },
    },
  },
};
const defaultGridSizeFloored = {
  typeOne_left_width: '2',
  typeOne_right_width: '1',
  typeTwo_left_width: '2',
  typeTwo_left_top_height: '2',
  typeTwo_left_bottom_height: '1',
  typeTwo_right_width: '1',
  typeThree_left_width: '1',
  typeThree_left_top_height: '1',
  typeThree_left_bottom_height: '1',
  typeThree_right_width: '1',
  typeThree_right_top_height: '1',
  typeThree_right_bottom_height: '1',
};

const check = (state) => {
  const global = (state) => {
    if (Object.keys(state).length > 1) {
      return true;
    }
    const keys = ['property', 'question'];
    return Object.entries(state).reduce((o, [key, value]) => {
      if (!o) {
        return Object.keys(value).length > (keys.includes(key) && value?.normal ? 1 : 0);
      }
      return o;
    }, false);
  };

  return global(state.globalFilter.query) || state.filter !== null;
};

const mergeFilters = (state, filter, nested) => {
  const keys = ['property', 'question'];
  const nestedPropertyKeys = ['normal', 'archive', 'investment', 'investmentCategory'];
  keys.forEach((item) => {
    nestedPropertyKeys.forEach((key) => {
      const stateValue = state?.[item]?.[key];
      if (stateValue != null && filter?.[item]?.[key] == null) {
        filter[item][key] = stateValue;
      }
    });
  });

  state = nested ? merge(state, filter) : filter;

  keys.forEach((item) => {
    nestedPropertyKeys.forEach((key) => {
      if (state?.[item]?.[key] === false && key !== 'normal') {
        delete state[item][key];
      }
    });
  });
  return state;
};

export const gridFiltersReset = createAsyncThunk('grid/gridFiltersReset', async (payload, { dispatch }) => {
  const { grid } = payload;
  dispatch(globalFilterReset({ grid }));
  return grid;
});

const initSelectedRow = {
  index: null,
  itemId: null,
  cell: {
    column: null,
    value: null,
  },
  cells: [],
};

const initGlobalPropertyFilterQuery = {
  property: {
    normal: true,
  },
};

const initGlobalQuestionFilterQuery = {
  question: {
    normal: true,
  },
};

const defaultGridState = {
  size: defaultGridSize,
  property: {
    rows: [],
    rowsFull: [],
    columnsData: null,
    selectedRow: initSelectedRow,
    selectedCheckboxRow: null,
    filter: null,
    detailsPropertyId: null,
    globalFilter: {
      query: initGlobalPropertyFilterQuery,
    },
    isFiltered: false,
    renumberId: null,
  },
  question: {
    rows: [],
    rowsFull: [],
    columnsData: null,
    selectedRow: initSelectedRow,
    selectedCheckboxRow: null,
    filter: null,
    globalFilter: {
      query: initGlobalQuestionFilterQuery,
    },
    isFiltered: false,
  },
  deselectAllCheckboxes: '',
  renumberedIds: null,
  lastSelectedGrid: GRID_PROPERTY,
  reload: '',
  preventLoading: false,
};
// Sprawdza czy size zapisany w localstorage jest poprawny
const isStoredSizeValid = (size) => {
  const flooredSize = floorObject(size, null, '_');
  return Object.keys(defaultGridSizeFloored).reduce((isTrue, key) => {
    if (isTrue && key in flooredSize && typeof flooredSize[key] === typeof defaultGridSizeFloored[key]) return true;
    return false;
  }, true);
};
export const gridSlice = createSlice({
  name: 'grid',
  initialState: () => {
    const gridSize = localStorage.getItem('gridSize');
    if (gridSize) {
      try {
        const fixedGridSize = JSON.parse(gridSize);
        // jeżeli ktoś manualnie coś zmieni w localstorage i czegoś będzie brakować to używa defaultowej pozycji
        if (!isStoredSizeValid(fixedGridSize)) return defaultGridState;
        return { ...defaultGridState, size: fixedGridSize };
      } catch (e) {}
    }
    return defaultGridState;
  },
  reducers: {
    selectGridCheckbox: (state, action) => {
      const {
        payload: { grid, rowIds },
      } = action;
      state[getGridName(grid)].selectedCheckboxRow = rowIds;
    },
    updateRowSelectMobile: (state, action) => {
      const {
        payload: { grid, id, check },
      } = action;
      const selectedRows = state[getGridName(grid)].selectedCheckboxRow || {};
      if (selectedRows[id] && !check) delete selectedRows[id];
      else if (!selectedRows[id] && check) selectedRows[id] = true;
      state[getGridName(grid)].selectedCheckboxRow = selectedRows;
    },
    selectGridRow: (state, action) => {
      const { grid, index, itemId, cell, cells } = action.payload;
      state[getGridName(grid)].selectedRow = { index, itemId, cell, cells };
      state.lastSelectedGrid = grid;
    },
    selectGridRowReset: (state, action) => {
      const { grid } = action.payload;
      state[getGridName(grid)].selectedRow = initSelectedRow;
    },
    selectGridCheckboxReset: (state, action) => {
      const { grid } = action.payload;
      const gridName = getGridName(grid);
      state[gridName].selectedCheckboxRow = null;
      state.deselectAllCheckboxes = grid;
    },
    setDeselectAllCheckboxes: (state, action) => {
      state.deselectAllCheckboxes = action.payload;
    },
    setDetailsPropertyId: (state, action) => {
      state.property.detailsPropertyId = action.payload;
    },
    setCurrentGridRows: (state, action) => {
      const { grid, rows } = action.payload;
      const ids = rows.map((i) => i.I);
      state[getGridName(grid)].rows = ids;
      state[getGridName(grid)].rowsFull = rows;
      const updatedSelectedRows = Object.keys(state[getGridName(grid)].selectedCheckboxRow || {}).reduce((obj, i) => {
        if (ids.includes(Number(i))) obj[i] = true;
        return obj;
      }, {});
      state[getGridName(grid)].selectedCheckboxRow = updatedSelectedRows;
    },
    gridReload: (state, action) => {
      state.reload = action.payload.grid;
      state.preventLoading = action.payload.preventLoading || false;
    },
    renumberGridIds: (state, action) => {
      const { ids } = action.payload;
      state.renumberedIds = ids;
      state.property.renumberId = ids;
      const detailsPropertyId = state.property.detailsPropertyId;
      if (detailsPropertyId && ids && ids?.[detailsPropertyId]) {
        state.property.detailsPropertyId = ids[detailsPropertyId];
      }
    },
    rememberPropertyIdRenumbering: (state, action) => {
      const { oldPropertyId, newPropertyId } = action.payload;

      let newRenumberedPropertyIdsSinceLoad = _.cloneDeep(state.renumberedPropertyIdsSinceLoad ?? {});

      newRenumberedPropertyIdsSinceLoad = _.mapValues(newRenumberedPropertyIdsSinceLoad, (value) => {
        if (value === oldPropertyId) {
          value = newPropertyId;
        }

        return value;
      });

      newRenumberedPropertyIdsSinceLoad[oldPropertyId] = newPropertyId;

      state.renumberedPropertyIdsSinceLoad = newRenumberedPropertyIdsSinceLoad;
    },
    changeGridFilter: (state, action) => {
      const { grid, filter } = action.payload;
      const gridName = getGridName(grid);
      state[gridName].filter = filter;
      state[gridName].isFiltered = check(state[gridName]);
    },
    setColumnsData: (state, action) => {
      const { grid, data } = action.payload;
      state[getGridName(grid)].columnsData = data;
    },
    updateColumnWidth: (state, action) => {
      const { grid, name, width } = action.payload;
      const columns = state[getGridName(grid)].columnsData.data;
      const columnIndex = columns.findIndex((column) => column.grids_columns_data_name === name);
      if (columnIndex !== -1) {
        columns[columnIndex].grids_columns_width = `${width}px`;
      }
    },
    //// filters
    loadDataGridByFilter: (state, action) => {
      const { grid, filter, nested = false, filterEmpty = false } = action.payload;
      const gridName = getGridName(grid);

      state[gridName].globalFilter.query = mergeFilters(state[gridName].globalFilter.query, filter, nested);
      state[gridName].isFiltered = check(state[gridName]) && !filterEmpty;
    },
    gridResize: (state, action) => {
      const gridDisplayType = action.payload;
      switch (gridDisplayType) {
        case 1:
          state.size.typeOne = { ...state.size.typeOne, ...getFixedGridSize(1) };
          break;
        case 2:
          state.size.typeTwo = { ...state.size.typeTwo, ...getFixedGridSize(2) };
          break;
        case 3:
          state.size.typeThree = { ...state.size.typeThree, ...getFixedGridSize(3) };
          break;
        default:
          break;
      }
      localStorage.setItem('gridSize', JSON.stringify(state.size));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(gridFiltersReset.fulfilled, (state, action) => {
      const grid = action.payload;
      const gridName = getGridName(grid);
      state[gridName].isFiltered = false;
      state[gridName].filter = null;
      state[gridName].globalFilter.query =
        grid === GRID_PROPERTY ? initGlobalPropertyFilterQuery : initGlobalQuestionFilterQuery;
    });
  },
});

export const {
  selectGridRow,
  selectGridRowReset,
  selectGridCheckbox,
  selectGridCheckboxReset,
  updateRowSelectMobile,
  setDetailsPropertyId,
  setDeselectAllCheckboxes,
  setCurrentGridRows,
  changeGridFilter,
  setColumnsData,
  updateColumnWidth,
  gridReload,
  renumberGridIds,
  rememberPropertyIdRenumbering,
  loadDataGridByFilter,
  gridResize,
} = gridSlice.actions;

export const isFilteredSelector = (state, grid) => state.grid[getGridName(grid)].isFiltered;
export const filterSelector = (state, grid) => state.grid[getGridName(grid)].filter;
export const globalFilterSelector = (state, grid) => state.grid[getGridName(grid)].globalFilter;
export const globalFilterQuerySelector = (state, grid) => state.grid[getGridName(grid)].globalFilter.query;
export const selectedCheckboxSelector = (state, grid) => {
  const ids = state.grid[getGridName(grid)].selectedCheckboxRow;
  return ids ? Object.keys(ids).map((i) => Number(i)) : ids;
};
export const selectedCheckboxRowSelector = (state, grid) => state.grid[getGridName(grid)].selectedCheckboxRow;
export const selectedRowSelector = (state, grid) => state.grid[getGridName(grid)].selectedRow;
export const columnsDataSelector = (state, grid) => state.grid[getGridName(grid)].columnsData;
export const currentGridRowsSelector = (state, grid) => state.grid[getGridName(grid)].rows;
export const currentGridRowsFullSelector = (state, grid) => state.grid[getGridName(grid)].rowsFull;
export const deselectAllCheckboxesSelector = (state) => state.grid.deselectAllCheckboxes;
export const detailsPropertyIdSelector = (state) => state.grid.property.detailsPropertyId;
export const gridReloadSelector = (state) => state.grid.reload;
export const gridRenumberIdsSelector = (state) => state.grid.renumberedIds;
export const gridRenumberOfferIdSelector = (state) => state.grid.property.renumberId;
export const gridRenumberedPropertyIdsSinceLoadSelector = (state) => state.grid.renumberedPropertyIdsSinceLoad ?? {};
export const gridPreventLoadingSelector = (state) => state.grid.preventLoading;
export const gridSizeLeftTopSelector = (state) => state.grid?.size?.left?.top;
export const gridSizeRightTopSelector = (state) => state.grid?.size?.right?.top;
export const getGridSize = (state) => {
  switch (state.user.gridDisplayType) {
    case 1:
      return state.grid.size.typeOne;
    case 2:
      return state.grid.size.typeTwo;
    case 3:
      return state.grid.size.typeThree;
    default:
      return state.grid.size.typeOne;
  }
};

export default gridSlice.reducer;
