import api from './service';
import { isPropertyGrid } from 'module/grid/helper';
import { format } from 'date-fns';

//Grids
export const gridData = (grid, type, filters) =>
  api.post(`v1/${isPropertyGrid(grid) ? 'properties' : 'question'}/grid-data?categoryId=${type}`, filters);

export const gridColumns = (categoryId, gridType) => api.get(`v1/grid/columns/${categoryId}/${gridType}`);
export const gridColumnsVisible = (categoryId, gridType) =>
  api.get(`v1/grid/columns/${categoryId}/${gridType}?visible=1`);

export const gridPropertyDetails = (propertyId) => api.get(`v1/properties/preview/${propertyId}`);
export const gridQuestionDetails = (questionId) => api.get(`v1/question/preview/${questionId}`);

export const gridColumnSettings = (id, payload) => api.patch(`v1/grid/columns/${id}`, payload);
export const gridColumnVisible = (columns) => api.put(`v1/grid/columns/visible`, columns);

//Property
export const propertiesDelete = (ids) => api.delete(`v1/properties/delete`, ids);
export const findLocationAssignedToProperty = (encodeText, assigned, signal) =>
  api.get(`v1/location/property/search/${encodeText}?assigned=${assigned}`, signal);
export const findStreetAssignedToProperty = (encodeText, assigned, signal) =>
  api.get(`v1/street/property/search/${encodeText}?assigned=${assigned}`, signal);
export const duplicateProperty = (data) =>
  api.post(`v1/properties/form/${data.categoryId}/${data.propertyId}/duplicate`, data.data);
export const getDuplicateStatus = (data) =>
  api.get(`v1/properties/form/${data.categoryId}/${data.propertyId}/duplicate/processStatuses/${data.token}`);
export const findPropertyDuplicates = (data) => api.post(`v1/properties/findDuplicates`, data);
export const findQuestionDuplicates = (data) => api.post(`v1/question/findDuplicates`, data);

//Statistics
export const findStatistics = (data) => api.post(`v1/statistics`, data.data);

//Regenerate photos
export const regenerate = () => api.post(`v1/settings/photos/regenerate`);
export const getRegenerate = () => api.get(`v1/settings/photos/regenerate`);

//Grid filters
export const createFilter = async (grid, categoryId, filter, successCallback, errorCallback) => {
  const connect = () => api.post(`v1/filters/${grid}/${categoryId}`, filter);
  return call(
    connect,
    (res) => {
      return successCallback(res.data);
    },
    (res) => {
      if (res?.data?.code) {
        return errorCallback(res.data);
      }

      throw Error(JSON.stringify(res));
    },
  );
};

export const updateFilter = async (filterId, filter, successCallback, errorCallback) => {
  const connect = () => api.put(`v1/filters/${filterId}`, filter);
  return call(
    connect,
    (res) => {
      return successCallback(res.data);
    },
    (res) => {
      if (res?.data?.code) {
        return errorCallback(res.data);
      }

      throw Error(JSON.stringify(res));
    },
  );
};

export const getFilterNames = async (grid, categoryId, successCallback, errorCallback) => {
  const connect = () => api.get(`v1/filters/${grid}/${categoryId}`);
  return call(connect, successCallback, errorCallback);
};

export const deleteFilter = async (filterId, successCallback, errorCallback) => {
  const connect = () => api.delete(`v1/filters/${filterId}`);
  return call(connect, successCallback, errorCallback);
};

export const loadFilter = async (id, successCallback, errorCallback) => {
  const connect = () => api.get(`v1/filters/${id}`);
  return call(connect, successCallback, errorCallback);
};

export const filterSettings = async (successCallback, errorCallback) => {
  return call(() => api.get(`v1/grid/filter/property/settings`), successCallback, errorCallback);
};

// User
export const userSettings = async (successCallback, errorCallback) => {
  return call(() => api.get('v1/settings'), successCallback, errorCallback);
};

//

// Administration

// Export settings
export const getExports = () => api.get(`v1/exports`);
export const getEditExportData = (id) => api.get(`v1/exports/edit/${id}`);
export const updateExport = (data) => api.put(`v1/exports/edit/${data.id}`, data);
export const getExportsFormData = () => api.get(`v1/exports/create`);
export const createExport = (data) => api.post(`v1/exports/create`, data);
export const deleteExport = (id) => api.delete(`v1/exports/delete/${id}`);
export const toggleExport = (data) => api.post(`v1/exports/enabled/${data.id}`, data);
export const forceExport = (data) => api.post(`v1/exports/force`, data);
export const updateExportOffers = (id) => api.post(`v1/exports/update-offers/${id}`);
export const testExportFtp = (data) => api.post(`v1/exports/integration-test`, data);
export const deselectExportOffers = (id) => api.post(`v1/exports/deselect-offers/${id}`);
export const selectExportOffers = (data) => api.post(`v1/exports/select-offers/${data.id}/${data.statusId}`);
export const getExportOffers = (id) => api.get(`v1/exports/offers/${id}`);
export const getExportOAuth2Url = ({ exportId, isImport }) => api.get(`v1/exports/oauth2-url/${exportId}/${isImport}`);

//Settings -> General
export const getGeneralData = () => api.get(`v1/settings/general`);
export const updateGeneralData = (data) => api.post(`v1/settings/general`, data);
//Settings -> Internet
export const getInternetSettings = () => api.get(`v1/settings/internet`);
export const updateInternetSettings = (data) => api.post(`v1/settings/internet`, data);
//Settings -> Duplicates
export const getDuplicates = () => api.get(`v1/settings/duplicates`);
export const updateDuplicates = (data) => api.post(`v1/settings/duplicates`, data);
//Settings -> Prints
export const getPrints = () => api.get(`v1/settings/prints`);
export const updatePrints = (data) => api.post(`v1/settings/prints`, data);
//Settings -> Imports
export const getImports = () => api.get(`v1/settings/imports`);
export const addImport = (data) => api.post(`v1/settings/imports`, data);
export const updateImport = (data) => api.put(`v1/settings/imports/${data.id}`, data);
export const deleteImport = (data) => api.delete(`v1/settings/imports/${data.id}`);
//Settings -> Old data settings
export const getOldSettingsData = () => api.get(`v1/settings/old-data-deletion`);
export const updateOldSettingsData = (data) => api.patch(`v1/settings/old-data-deletion`, data);

//Administration -> loginHistory
export const getActiveLoginData = () => api.get(`v1/loginHistory/active`);
export const getLoginHistoryData = (data) => api.post(`v1/loginHistory`, data.data);

//    Stamps
export const getStampsData = () => api.get(`v1/stamps`);
export const addNewStamp = (data) => api.post(`v1/stamps`, data);
export const updateStamp = (data) => api.patch(`v1/stamps/${data.id}`, data);
export const deleteStamp = ({ id }) => api.delete(`v1/stamps/${id}`);

//CustomFields
export const getCustomFieldsData = () => api.get(`v1/custom-fields`);
export const addNewCustomFields = (data) => api.post(`v1/custom-fields`, data);
export const updateCustomFields = (data) => api.put(`v1/custom-fields`, data);
export const deleteCustomFields = ({ id }) => api.delete(`v1/custom-fields/${id}`);

//statuses
export const getStatuses = async (successCallback, errorCallback) => {
  const connect = () => api.get(`v1/status/admin-page-list`);
  return call(connect, successCallback, errorCallback);
};

export const createStatuses = async (status, successCallback, errorCallback) => {
  const connect = () => api.post(`v1/status/admin-page-list`, status);
  return call(connect, successCallback, errorCallback);
};

export const updateStatuses = async (status, successCallback, errorCallback) => {
  const connect = () => api.put(`v1/status/admin-page-list/${status.id}`, status);
  return call(connect, successCallback, errorCallback);
};

export const deleteStatuses = async (id, successCallback, errorCallback) => {
  const connect = () => api.delete(`v1/status/admin-page-list/${id}`);
  return call(connect, successCallback, errorCallback);
};

//dictionaries
export const dictionaryWithValues = async (successCallback, errorCallback) => {
  return call(() => api.get('v1/dictionaries/with-values'), successCallback, errorCallback);
};
export const dictionaryValues = (id) => api.get(`v1/dictionaries/${id}`);
export const dictionaryValueDelete = (data) => api.delete(`v1/dictionaries/${data.id}`, { newValueId: data.newId });
export const dictionaryValueUpdate = (id, data) => api.put(`v1/dictionaries/${id}`, data);
export const dictionaryValueCreate = (data) => api.post(`v1/dictionaries`, data);

//required fields
export const requiredFields = (categoryId, type) => api.get(`v1/required-fields/possibilities/${type}/${categoryId}`);
export const requiredCurrentFields = () => api.get(`v1/required-fields/current`);
export const requiredFieldsUpdate = (data) => api.put(`v1/required-fields/update`, data);

//match question to offers
export const getMatchQTOFields = async (type, successCallback, errorCallback) => {
  const connect = () => api.get(`v1/matching/types/question-to-property/${type}`);
  return call(connect, successCallback, errorCallback);
};
export const sendMatchQTO = async (question, type, data, successCallback, errorCallback) => {
  const connect = () => api.post(`v1/matching/types/question-to-property/${type}/${question}`, data);
  return call(connect, successCallback, errorCallback);
};
//match offer to questions
export const getMatchOTQFields = async (type, successCallback, errorCallback) => {
  const connect = () => api.get(`v1/matching/types/property-to-question/${type}`);
  return call(connect, successCallback, errorCallback);
};
export const sendMatchOTQ = async (offer, type, data, successCallback, errorCallback) => {
  const connect = () => api.post(`v1/matching/types/property-to-question/${type}/${offer}`, data);
  return call(connect, successCallback, errorCallback);
};

// credentials
export const getCredentials = () => api.get(`v1/credentials`);
export const getCredentialsGroup = (id) => api.get(`v1/credentials/form/${id}`);
export const getCredentialsSettings = () => api.get(`v1/credentials/form`);
export const addCredentialsGroup = (data) => api.post(`v1/credentials`, data);
export const editCredentialsGroup = (data) => api.put(`v1/credentials/${data.id}`, data.data);
export const deleteCredentialsGroup = (id) => api.delete(`v1/credentials/${id}`);

//agents
export const getAgentsData = () => api.get(`v1/agents/settings`);
export const getAgentData = (id) => api.get(`v1/agents/${id}`);
export const updateAgent = (data) => api.put(`v1/agents/${data.id}`, data);
export const createAgent = (data) => api.post(`v1/agents`, data);
export const deleteAgent = (data) => api.delete(`v1/agents/${data.id}/${data.newId}`);
export const changeAgentPassword = (data) => api.patch(`v1/agents/password`, data);
export const agentSmtpTest = (data) => api.post(`v1/agents/smtp-test`, data);
export const changeAgentStatus = (data) => api.patch(`v1/agents/active-status`, data);
//profil
export const getProfileData = () => api.get(`v1/agents/profile`);
export const updateProfile = (data) => api.put(`v1/agents/profile`, data);

//agencies
export const getAgenciesData = () => api.get(`v1/agencies/settings`);
export const getAgencyData = (id) => api.get(`v1/agencies/${id}`);
export const updateAgency = (data) => api.put(`v1/agencies/${data.id}`, data);
export const createAgency = (data) => api.post(`v1/agencies`, data);
export const deleteAgency = (data) => api.delete(`v1/agencies/${data.id}/${data.newId}`);

//locations
export const getLocation = ({ level, parentId, parentLevel }) => {
  const url = `v1/location/${level}` + (parentId ? `/${parentId}${parentLevel ? `/${parentLevel}` : ''}` : '');
  return api.get(url);
};
export const addLocation = (data) => api.post(`v1/location`, data);
export const updateLocation = (data) => api.patch(`v1/location/${data.id}`, data);

//street
export const streetSearch = (encodeText, assigned, signal) =>
  api.get(`v1/street/search/${encodeText}?assigned=${assigned}`, signal);
export const addStreet = (data) => api.post(`v1/street`, data);
export const updateStreet = (data) => api.put(`v1/street/${data.id}`, data);
export const deleteStreet = (data) => api.delete(`v1/street/${data.id}/${data.newId}`, data);

// offer -> general
export const getInvestment = (data) => api.get(`v1/properties/form/investments/${data.propertyId}`);
export const addInvestment = (data) => api.post(`v1/properties/form/investments`, data.data);
export const updateInvestment = (data) => api.patch(`v1/properties/form/investments/${data.propertyId}`, data.data);

// offer -> general
export const getOfferSettings = () => api.get(`v1/properties/settings`);
export const getOfferGeneral = (data) => api.get(`v1/properties/form/${data.categoryId}/${data.propertyId}`);
export const addOfferGeneral = (data) => api.post(`v1/properties/form/${data.categoryId}`, data.data);
export const updateOfferGeneral = (data) =>
  api.patch(`v1/properties/form/${data.categoryId}/${data.propertyId}`, data.data);
export const getSharingLink = (data) => api.get(`v1/properties/form/${data.categoryId}/${data.propertyId}/cardUrl`);

// offer -> owners
export const getCustomerSettings = () => api.get(`v1/customers/settings`);
export const getCompanySettings = () => api.get(`v1/companies/settings`);
export const addCustomer = (data) => api.post(`v1/customers`, data);
export const addCompany = (data) => api.post(`v1/companies`, data);
export const addTransferPersonalData = (data) => api.post(`v1/customers/${data.id}/idDataTransfers`, data);
export const addOwnerToProperty = (data) => api.post(`v1/properties/${data.propertyId}/owners`, data);
export const editCustomer = (data) => api.put(`v1/customers/${data.id}`, data);
export const editCompany = (data) => api.put(`v1/companies/${data.id}`, data);
export const getOwners = (propertyId) => api.get(`v1/properties/${propertyId}/owners`);
export const getCustomerEditData = (id) => api.get(`v1/customers/${id}`);
export const getCompanyEditData = (id) => api.get(`v1/companies/${id}`);
export const getCustomerDetails = (id) => api.get(`v1/customers/${id}/details`);
export const getCompanyDetails = (id) => api.get(`v1/companies/${id}/details`);
export const getCustomerTransferData = (id) => api.get(`v1/customers/${id}/idDataTransfers`);
export const customerSearch = (text) => api.get(`v1/customers/search/${text}`);
export const companySearch = (text) => api.get(`v1/companies/search/${text}`);
export const deleteCustomer = (id) => api.delete(`v1/customers/${id}`);
export const deleteCompany = (id) => api.delete(`v1/companies/${id}`);
export const deleteOwner = (data) => api.delete(`v1/properties/${data.propertyId}/owners/${data.type}-${data.id}`);
// pdf transfer data
export const getTransferDataPdfProperty = (id, propertyId) =>
  `v1/customers/${id}/idDataTransfers/pdf?propertyId=${propertyId}`;
export const getTransferDataPdfQuestion = (id, questionId) =>
  `v1/customers/${id}/idDataTransfers/pdf?questionId=${questionId}`;
export const getTransferDataPdf = (id) => `v1/customers/${id}/idDataTransfers/pdf`;

// offer -> rooms
export const getRooms = (data) => api.get(`v1/properties/form/${data.categoryId}/${data.propertyId}/rooms`);
export const addRoom = (data) => api.post(`v1/properties/form/${data.categoryId}/${data.propertyId}/rooms`, data.data);
export const updateRoom = (data) =>
  api.put(`v1/properties/form/${data.categoryId}/${data.propertyId}/rooms/${data.data.id}`, data.data);
export const deleteRoom = (data) =>
  api.delete(`v1/properties/form/${data.categoryId}/${data.propertyId}/rooms/${data.data.id}`, data.data);

// offer -> images

export const getImages = (data) => api.get(`v1/properties/photos/${data.propertyId}`);
export const updateImage = (data) => api.put(`v1/properties/photos/${data.propertyId}`, data.data);
export const addImage = (data) => api.post(`v1/properties/photos/${data.propertyId}`, data.data);
export const deleteImage = (data) => api.delete(`v1/properties/photos/${data.propertyId}`, data.data);
export const editImage = (data) => api.put(`v1/properties/photos/${data.propertyId}/edit/${data.photoId}`, data.data);
export const updateImageDetails = (data) => api.patch(`v1/properties/photos/${data.propertyId}`, data.data);

// offer -> documents
export const getDocuments = (propertyId) => api.get(`v1/documents/property/${propertyId}`);
export const addDocument = (data) => api.post(`v1/documents/property/${data.propertyId}`, data.data);
export const deleteDocument = (data) => api.delete(`v1/documents/property/${data.propertyId}`, data.data);
export const publishDocuments = (data) => api.put(`v1/documents/property/${data.propertyId}/publish`, data.data);
export const updateDocumentName = (data) => api.put(`v1/documents/property/${data.propertyId}/change-name`, data.data);
export const updateDocumentFile = (data) => api.put(`v1/documents/property/${data.propertyId}/new-version`, data.data);
export const updateDocumentPosition = (data) =>
  api.put(`v1/documents/property/${data.propertyId}/change-position`, data.data);

// offer -> calendar
export const getOfferCalendar = (data) => api.get(`v1/properties/form/${data.categoryId}/${data.propertyId}/calendar`);

// offer -> activities
export const getActivities = (data) => api.get(`v1/properties/form/${data.categoryId}/${data.propertyId}/activities`);
export const addActivity = (data) =>
  api.post(`v1/properties/form/${data.categoryId}/${data.propertyId}/activities`, data.data);
export const updateActivity = (data) =>
  api.put(`v1/properties/form/${data.categoryId}/${data.propertyId}/activities/${data.activityId}`, data.data);
export const deleteActivity = (data) =>
  api.delete(`v1/properties/form/${data.categoryId}/${data.propertyId}/activities/${data.activityId}`);
export const getQuestionList = (data) =>
  api.get(`v1/properties/form/${data.categoryId}/${data.propertyId}/activities/questionList/${data.data}`);

// offer -> events
export const getEvents = (propertyId) => api.get(`v1/events/property/${propertyId}`);

// offer -> notes
export const getNotes = (data) => api.get(`v1/properties/form/${data.categoryId}/${data.propertyId}/notes`);
export const addNote = (data) => api.post(`v1/properties/form/${data.categoryId}/${data.propertyId}/notes`, data.data);
export const updateNote = (data) =>
  api.put(`v1/properties/form/${data.categoryId}/${data.propertyId}/notes/${data.noteId}`, data.data);
export const deleteNote = (data) =>
  api.delete(`v1/properties/form/${data.categoryId}/${data.propertyId}/notes/${data.noteId}`);

// offer -> archive
export const getArchives = (data) => api.get(`v1/properties/form/${data.categoryId}/${data.propertyId}/archive`);
export const archiveOffer = (data) =>
  api.post(`v1/properties/form/${data.categoryId}/${data.propertyId}/archive/add`, data.data);
export const restoreArchive = (data) =>
  api.post(`v1/properties/form/${data.categoryId}/${data.propertyId}/archive/restore`, data.data);

// question -> general
export const getQuestionSettings = () => api.get(`v1/question/settings`);
export const getQuestion = (data) => api.get(`v1/question/${data.categoryId}/${data.questionId}`);
export const addQuestion = (data) => api.post(`v1/question/${data.categoryId}`, data.data);
export const updateQuestion = (data) => api.patch(`v1/question/${data.categoryId}/${data.questionId}`, data.data);
export const deleteQuestion = (data) => api.delete(`v1/question/${data.categoryId}/${data.questionId}`);
export const refreshQuestion = (questionId) => api.post(`v1/question/${questionId}/refresh`);
// question -> seeker
export const getSeekers = (data) => api.get(`v1/question/${data.questionId}/seekers`);
export const linkSeeker = (data) => api.post(`v1/question/${data.questionId}/seekers`, data);
export const deleteSeekerLink = (data) => api.delete(`v1/question/${data.questionId}/seekers/${data.type}-${data.id}`);

// question -> events
export const getQuestionEvents = (questionId) => api.get(`v1/events/question/${questionId}`);

// question -> archive
export const getArchivesQ = (data) => api.get(`v1/question/${data.categoryId}/${data.questionId}/archive`);
export const archiveQuestion = (data) =>
  api.post(`v1/question/${data.categoryId}/${data.questionId}/archive/add`, data.data);
export const restoreArchivedQ = (data) =>
  api.post(`v1/question/${data.categoryId}/${data.questionId}/archive/restore`, data.data);

// question -> archive
export const getHistory = (data) => api.get(`v1/question/${data.categoryId}/${data.questionId}/activities`);
export const addHistory = (data) => api.post(`v1/question/${data.categoryId}/${data.questionId}/activities`, data.data);
export const updateHistory = (data) =>
  api.put(`v1/question/${data.categoryId}/${data.questionId}/activities/${data.activityId}`, data.data);
export const deleteHistory = (data) =>
  api.delete(`v1/question/${data.categoryId}/${data.questionId}/activities/${data.activityId}`);
export const getOfferList = (data) =>
  api.get(`v1/question/${data.categoryId}/${data.questionId}/activities/propertyList/${data.text}`);

// question -> documents
export const getDocumentsQ = (questionId) => api.get(`v1/documents/question/${questionId}`);
export const addDocumentQ = (data) => api.post(`v1/documents/question/${data.questionId}`, data.data);
export const deleteDocumentQ = (data) => api.delete(`v1/documents/question/${data.questionId}`, data.data);
export const updateDocumentNameQ = (data) => api.put(`v1/documents/question/${data.questionId}/change-name`, data.data);
export const updateDocumentFileQ = (data) => api.put(`v1/documents/question/${data.questionId}/new-version`, data.data);
export const updateDocumentPositionQ = (data) =>
  api.put(`v1/documents/question/${data.questionId}/change-position`, data.data);

// question -> calendar
export const getQuestionCalendar = (data) => api.get(`v1/question/${data.categoryId}/${data.questionId}/calendar`);

// archive multiple offer question
export const archiveOffers = (data) => api.post(`v1/properties/archive/addMultiple`, data);
export const archiveQuestions = (data) => api.post(`v1/question/archive/addMultiple`, data);
// restore multiple offer question
export const restoreOffers = (data) => api.post(`v1/properties/archive/restoreMultiple`, data);
export const restoreQuestions = (data) => api.post(`v1/question/archive/restoreMultiple`, data);

// renumber and refresh
export const renumberProperty = (ids) => api.post(`v1/properties/renumber`, ids);
export const renumberCheck = (ids) => api.post(`v1/properties/renumber/check`, ids);
export const refreshProperty = (ids) => api.post(`v1/properties/refresh`, ids);

// assign offers to questions
export const assignToQuestions = (data) => api.post(`v1/properties/assignToQuestions`, data);

// change leading agent
export const changeLeadingAgentProperty = (data) => api.post(`v1/properties/changeAssignedAgent`, data);
export const changeLeadingAgentQuestion = (data) => api.post(`v1/question/changeAssignedAgent`, data);

// photo info (grid hover)
export const getPhotoInfo = (categoryId, propertyId) => api.get(`v1/properties/photoInfo/${categoryId}/${propertyId}`);

// email config api
export const getAgentSmtpInfo = (agentId) => api.get(`v1/mail/agent/${agentId}/info`);
export const postAgentGmailCode = (agentId, data) => api.post(`v1/mail/agent/${agentId}/gmail`, data);
export const postTestSmtp = (data) => api.post(`v1/mail/test/smtp`, data);
export const postTestGmail = (data) => api.post(`v1/mail/test/gmail`, data);
export const putAgentSmtpInfo = (agentId, data) => api.put(`v1/mail/agent/${agentId}/smtp`, data);

// Owners seekers
export const getOwnersSeekers = () => api.get(`v1/customers`);

//prints and emails
export const getPrintsOrEmailsUrl = (type, data) => api.post(`v1/${type}`, data);

// Invoices
export const getInvoiceUrl = () => api.get(`v1/invoices`);

// Helpdesk
export const getHelpdeskReports = () => api.get(`v1/helpdesk`);
export const getHelpdeskReportDetails = (reportId) => api.get(`v1/helpdesk/task/${reportId}`);
export const updateHelpdeskReport = (reportId, data) => api.patch(`v1/helpdesk/task/${reportId}`, data);
export const getHelpdeskSettings = () => api.get(`v1/helpdesk/task`);
export const addHelpdeskReport = (data) => api.post(`v1/helpdesk/task`, data);
export const getHelpdeskFile = (reportId, fileId) => api.get(`v1/helpdesk/task/${reportId}/file/${fileId}`);

// Search
export const getFastSearch = (data, signal) => api.post(`v1/search/fast`, data, signal);

// daily disabled exports
export const getDisabledExports = () => api.get(`v1/disabledExportReport`);
export const markDisabledExportsRead = () => api.post(`v1/disabledExportReport/markAsRead`);

// calendar
export const getCalendarEntries = (fromDate, toDate) => {
  fromDate = format(fromDate, 'yyyy-MM-dd');
  toDate = format(toDate, 'yyyy-MM-dd');
  return api.get(`v1/calendar/entries?fromDate=${fromDate}&toDate=${toDate}`);
};

export const addCalendarEntry = (data) => api.post('v1/calendar/entries', data);
export const updateCalendarEntry = (calendarEntryId, data) => api.put(`v1/calendar/entries/${calendarEntryId}`, data);
export const deleteCalendarEntry = (calendarEntryId) => api.delete(`v1/calendar/entries/${calendarEntryId}`);
export const getEntityToAssignSearchResults = (query, signal) => {
  return api.post('v1/search/calendarEntryEntities', { text: query }, signal);
};
export const getNewCalendarPropertyRowData = (propertyId) =>
  api.get(`v1/calendar/entityRowData/properties/${propertyId}`);
export const getNewCalendarQuestionRowData = (questionId) =>
  api.get(`v1/calendar/entityRowData/questions/${questionId}`);
export const getNewCalendarCustomerRowData = (customerId) =>
  api.get(`v1/calendar/entityRowData/customers/${customerId}`);
export const getNewCalendarCompanyRowData = (companyId) => api.get(`v1/calendar/entityRowData/companies/${companyId}`);

export const getNotificationForAgent = async (successCallback, errorCallback) => {
  const connect = () => api.get(`v1/notification/agent`);
  return call(connect, successCallback, errorCallback);
};
export const markNotificationAsRead = (ids) => api.post(`v1/notification/markAsRead`, ids);

// Documents
export const getDocumentsRepo = () => api.get(`v1/documents`);
export const getDocumentRepo = (document_id) => `v1/documents/${document_id}`;
export const addDocumentRepo = (data) => api.post(`v1/documents`, data);
export const editDocumentRepo = (document_id, data) => api.put(`v1/documents/${document_id}`, data);
export const deleteDocumentRepo = (document_id) => api.delete(`v1/documents/${document_id}`);

//##########################################################################
const retry = (endpoint) => {
  return api.retry(endpoint, 5);
};

const call = async (endpoint, successCallback, errorCallback) => {
  const res = await retry(endpoint);
  if (res.status !== 1) {
    return errorCallback(res);
  }
  return successCallback(res);
};
