export const SERVER_URL = process.env.REACT_APP_SERVER_URL || window.location.protocol + '//' + window.location.host;
export const AGENCY_NAME = process.env.REACT_APP_AGENCY || window.location.pathname;

export const GRID_PROPERTY = 'PROPERTY';
export const GRID_QUESTION = 'QUESTION';
export const GRID_REFETCH = 'GRID_REFETCH';
export const GRID_REFETCH_ALL = 'GRID_REFETCH_ALL';
export const GRID_RENUMBER = 'GRID_RENUMBER';
export const GRID_SELECT_ROW_MOBILE = 'GRID_SELECT_ROW_MOBILE';

export const HEADER_MODAL_TOOLS_FEECALCULATOR = 'HEADER_MODAL_TOOLS_FEECALCULATOR';
export const MODAL_COLUMN_SETTINGS_PROPERTY = 'MODAL_COLUMN_SETTINGS_PROPERTY';
export const MODAL_COLUMN_SETTINGS_QUESTION = 'MODAL_COLUMN_SETTINGS_QUESTION';
export const HEADER_MODAL_DOCUMENTS_REPOSITORY = 'HEADER_MODAL_DOCUMENTS_REPOSITORY';
export const HEADER_MODAL_PROFIL = 'HEADER_MODAL_PROFIL';

export const HEADER_MODAL_DELETE_PROPERTY = 'HEADER_MODAL_DELETE_PROPERTY';
export const HEADER_MODAL_DELETE_QUESTION = 'HEADER_MODAL_DELETE_QUESTION';

export const HEADER_MODAL_MATCHING_OFFERS = 'HEADER_MODAL_MATCHING_OFFERS';
export const HEADER_MODAL_MATCHING_QUESTIONS = 'HEADER_MODAL_MATCHING_QUESTIONS';

export const HEADER_MODAL_NEW_INVESTMENT = 'HEADER_MODAL_NEW_INVESTMENT';
export const HEADER_MODAL_NEW_OFFER = 'HEADER_MODAL_NEW_OFFER';
export const HEADER_MODAL_NEW_QUESTION = 'HEADER_MODAL_NEW_QUESTION';

export const HEADER_MODAL_ARCHIVE_OFFERS = 'HEADER_MODAL_ARCHIVE_OFFERS';
export const HEADER_MODAL_RESTORE_OFFERS = 'HEADER_MODAL_RESTORE_OFFERS';
export const HEADER_MODAL_ARCHIVE_QUESTIONS = 'HEADER_MODAL_ARCHIVE_QUESTIONS';
export const HEADER_MODAL_RESTORE_QUESTIONS = 'HEADER_MODAL_RESTORE_QUESTIONS';

export const HEADER_MODAL_LINK_OFFERS_AND_INQUIRIES = 'HEADER_MODAL_LINK_OFFERS_AND_INQUIRIES';
export const HEADER_MODAL_CHANGE_LEADING_AGENT_OFFER = 'HEADER_MODAL_CHANGE_LEADING_AGENT_OFFER';
export const HEADER_MODAL_CHANGE_LEADING_AGENT_QUESTION = 'HEADER_MODAL_CHANGE_LEADING_AGENT_QUESTION';

export const HEADER_MODAL_RENUMBER = 'HEADER_MODAL_RENUMBER';
export const HEADER_MODAL_REFRESH = 'HEADER_MODAL_REFRESH';

export const HEADER_MODAL_ADMINISTRATION_REQUIRED_FIELDS = 'MODAL_REQUIRED_FIELDS';
export const HEADER_MODAL_ADMINISTRATION_STAMPS = 'HEADER_MODAL_ADMINISTRATION_STAMPS';
export const HEADER_MODAL_CUSTOM_FIELDS = 'HEADER_MODAL_CUSTOM_FIELDS';
export const HEADER_MODAL_ADMINISTRATION_EDIT_STATUSES = 'HEADER_MODAL_ADMINISTRATION_EDIT_STATUSES';
export const HEADER_MODAL_ADMINISTRATION_DICTIONARIES = 'HEADER_MODAL_ADMINISTRATION_DICTIONARIES';
export const HEADER_MODAL_ADMINISTRATION_DELETE_OLD_DATA = 'HEADER_MODAL_ADMINISTRATION_DELETE_OLD_DATA';
export const HEADER_MODAL_ADMINISTRATION_LOGIN_REPORTS = 'HEADER_MODAL_ADMINISTRATION_LOGIN_REPORTS';
export const HEADER_MODAL_ADMINISTRATION_EXPORT_SETTINGS = 'HEADER_MODAL_ADMINISTRATION_EXPORT_SETTINGS';
export const HEADER_MODAL_ADMINISTRATION_STATISTICS = 'HEADER_MODAL_ADMINISTRATION_STATISTICS';
export const HEADER_MODAL_ADMINISTRATION_AGENTS = 'HEADER_MODAL_ADMINISTRATION_AGENTS';
export const HEADER_MODAL_ADMINISTRATION_LOCATIONS = 'HEADER_MODAL_ADMINISTRATION_LOCATIONS';
export const HEADER_MODAL_ADMINISTRATION_SETTINGS = 'HEADER_MODAL_ADMINISTRATION_SETTINGS';
export const HEADER_MODAL_ADMINISTRATION_AGENCIES = 'HEADER_MODAL_ADMINISTRATION_AGENCIES';
export const HEADER_MODAL_ADMINISTRATION_PERMISSION_GROUPS = 'HEADER_MODAL_ADMINISTRATION_PERMISSION_GROUPS';

export const HEADER_MODAL_CLIENTS_OWNERS_SEEKERS = 'HEADER_MODAL_CLIENTS_OWNERS_SEEKERS';

export const SIDEBAR_CALENDAR = 'SIDEBAR_CALENDAR';
export const SIDEBAR_NOTIFICATIONS = 'SIDEBAR_NOTIFICATIONS';

export const HEADER_MODAL_HELP_HELPDESK = 'HEADER_MODAL_HELP_HELPDESK';

export const SIDEBAR_FILTERS_PROPERTY = 'SIDEBAR_FILTERS_PROPERTY';
export const SIDEBAR_FILTERS_QUESTION = 'SIDEBAR_FILTERS_QUESTION';

export const CUSTOMER_DATA_MODAL_RELATED_ENTITY_TYPE_PROPERTY = 'CUSTOMER_DATA_MODAL_RELATED_ENTITY_TYPE_PROPERTY';
export const CUSTOMER_DATA_MODAL_RELATED_ENTITY_TYPE_QUESTION = 'CUSTOMER_DATA_MODAL_RELATED_ENTITY_TYPE_QUESTION';

export const CATEGORY_HOUSE = 0;
export const CATEGORY_FLAT = 1;
export const CATEGORY_PLOT = 2;
export const CATEGORY_LOCAL = 4;
export const CATEGORY_OBJECT = 3;

export const CATEGORY_HOUSE_RENT = 0;
export const CATEGORY_HOUSE_SALE = 1;
export const CATEGORY_PLOT_RENT = 3;
export const CATEGORY_PLOT_SALE = 2;
export const CATEGORY_LOCAL_RENT = 8;
export const CATEGORY_LOCAL_SALE = 9;
export const CATEGORY_FLAT_RENT = 5;
export const CATEGORY_FLAT_SALE = 4;
export const CATEGORY_OBJECT_RENT = 6;
export const CATEGORY_OBJECT_SALE = 7;
export const CATEGORY_INVESTMENT = 14;

export const MAX_CATEGORY_NUMBER = 9;

export const DEFAULT_LAT = 51.91944;
export const DEFAULT_LNG = 19.14514;

export const SKELETON_FOREGROUND_COLOR = 'rgba(63, 162, 219, 0.01)';
export const SKELETON_BACKGROUND_COLOR = '#edeef6';

export const WARNING_ICON_EMPTY = `"data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e"`;
export const WARNING_ICON_FULL = `"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='%23ff0056' stroke='%23fff' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='5.8'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fff' stroke='none'/%3e%3c/svg%3e"`;

export const LINK_GEO = 'http://mapy.geoportal.gov.pl/imap';
export const LINK_EKW = 'https://przegladarka-ekw.ms.gov.pl/eukw_prz/KsiegiWieczyste/wyszukiwanieKW';
export const LINK_IMO_INSTRUCTION = 'https://instrukcja.imo.pl/index.php/Strona_g%C5%82%C3%B3wna';
export const LINK_IMO_FAQ = 'https://www.imo.pl/pomoc/imo/najczesciej-zadawane-pytania-na-temat-programu-imo/';
export const LINK_MOBILE_APP = 'https://play.google.com/store/apps/details?id=pl.possible.imomobile';

export const TEXT_EDITOR_STYLES =
  'body { font-family: "Lato", sans-serif; font-size: 0.8125rem; font-weight: 400; line-height: 1.4; color: var(--imo-grey-900); letter-spacing: 0.2px; }';

export const GOOGLE_API_KEY = 'AIzaSyBubpt7U-uKahJgJs2WSUKyrCbG4vQq3dM';

export const TYPE_DUPLICATE = 'duplicate';
export const TYPE_CALENDAR = 'calendar';
export const TYPE_EVENTS = 'events';

export const MAX_FILE_SIZE = 15728640;
export const MAX_FILE_SIZE_HELPDESK = 3122088;

export const PROPERTY_NO_PERMISSIONS = 'You have no permission to access this property.';
export const QUESTION_NO_PERMISSIONS = 'You have no permission to access this question.';
export const PROPERTY_NO_PERMISSIONS_MSG = 'Nie posiadasz uprawnień wymaganych do wyświetlenia tej oferty.';
export const QUESTION_NO_PERMISSIONS_MSG = 'Nie posiadasz uprawnień wymaganych do wyświetlenia tego zapytania.';
export const ADD_PROPERTY_NO_PERMISSIONS = 'Nie posiadasz uprawnień wymaganych do dodawania ofert.';
export const ADD_QUESTION_NO_PERMISSIONS = 'Nie posiadasz uprawnień wymaganych do dodawania zapytań.';

export const UNKNOWN_ERROR = 'Coś poszło nie tak, spróbuj ponownie później.';

export const POLISH_LANGUAGE_CODE = 'pl-PL';

export const SM_NUM_INPUT_MAX_LENGTH = 3;
export const MD_NUM_INPUT_MAX_LENGTH = 4;
export const MD2_NUM_INPUT_MAX_LENGTH = 5;
export const LG_NUM_INPUT_MAX_LENGTH = 6;
export const XL_NUM_INPUT_MAX_LENGTH = 10;
export const DEFAULT_NUM_INPUT_MAX_LENGTH = 10;
export const XXXL_NUM_INPUT_MAX_LENGTH = 30;

export const NO_OPTIONS_MSG = 'Brak wyników';
export const SELECT_NO_OPTIONS_MSG = () => NO_OPTIONS_MSG;
export const SELECT_LOADING_MSG = () => 'Wczytywanie...';

export const FEATURE_FLAG_FREE_MODE = 'freeMode';
export const FEATURE_FLAG_OTODOM_API = 'otodomApi';

export const CUSTOM_FIELDS_MAX_LEN_GTH = 70;

export const EXPORT_TYPE_OTODOM_API = 20;

export const LOCATION_LEVELS_NAME = {
  0: 'kraj',
  1: 'województwo',
  2: 'powiat',
  3: 'gmina wiejska',
  4: 'miasto',
  5: 'dzielnica',
  6: 'obszar w dzielnicy',
};
