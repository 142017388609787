import React, { useEffect, Suspense } from 'react';
import './scss/style.scss';
import { useDispatch } from 'react-redux';
import { useUser } from './hooks/useUser';
import { userValid } from './redux/userSlice';
import ErrorFallback from 'components/common/errorFallback/ErrorFallback';
import * as Sentry from '@sentry/react';
import SplashScreen from './components/SplashScreen';

const Layout = React.lazy(() => import('./containers/Layout'));

const App = () => {
  const { user } = useUser();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userValid());
  }, [dispatch]);

  return (
    <Sentry.ErrorBoundary fallback={({ resetError }) => <ErrorFallback resetError={resetError} />}>
      {user.id ? (
        <Suspense fallback={<SplashScreen />}>
          <Layout />
        </Suspense>
      ) : (
        <SplashScreen />
      )}
    </Sentry.ErrorBoundary>
  );
};

export default App;
