import React from 'react';
import styled from 'styled-components';
import errorLogo from '../../../assets/img/errorLogo.png';
import IMOButton from '../button/IMOButton';
import IMOIcon from '../IMOIcon';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  height: 100vh;
`;

const Image = styled.img`
  position: absolute;
  max-width: 100%;
  height: auto;
  opacity: 0.1;
`;
const InnerContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 11;
  & h1 {
    color: var(--imo-grey-700);
    font-size: 1.5rem;
    font-weight: 600;
  }
  & p {
    color: var(--imo-grey-700);
    font-size: 14px;
    margin: 0;
    font-weight: 600;
  }
  & button {
    margin: 2rem 0;
    width: 110%;
    justify-content: center;
  }
`;
const ContactContainer = styled.div`
  display: flex;
  justify-content: between;
  align-items: center;
  color: var(--imo-grey-700);
  & a {
    color: var(--imo-grey-700);
    font-size: 13px;
    margin-left: 1.5rem;
    &:hover {
      color: #3fa2db;
    }
  }
`;
const ErrorFallback = () => {
  const resetError = () => {
    window.location.reload();
  };
  return (
    <Container>
      <InnerContainer>
        <h1>Wystąpił błąd</h1>
        <p>Spróbuj ponownie lub skontaktuj się z pomocą IMO</p>
        <IMOButton color="primary" onClick={resetError}>
          Spróbuj ponownie
        </IMOButton>
        <ContactContainer>
          <IMOIcon name="help" size="24px" />
          <a href="tel:+48 (22) 203 62 03">+48 (22) 203 62 03</a>
          <a href="mailto:pomoc@imo.pl">pomoc@imo.pl</a>
        </ContactContainer>
      </InnerContainer>
      <Image src={errorLogo} alt="logo" />
    </Container>
  );
};

export default React.memo(ErrorFallback);
