import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const dictionariesAdapter = createEntityAdapter({
  selectId: (dictionary) => dictionary.id,
  sortComparer: (dictionaryA, dictionaryB) => dictionaryA.name.localeCompare(dictionaryB.name),
});

export const dictionarySlice = createSlice({
  name: 'dictionaries',
  initialState: {
    dictionaries: dictionariesAdapter.getInitialState(),
    isLoading: false,
  },
  reducers: {
    addDictionaries: (state, action) => {
      dictionariesAdapter.setAll(state.dictionaries, action.payload);
    },
    addValueInDictionary: (state, action) => {
      const { dictionaryId, valueId, valueName } = action.payload;
      const dictionary = dictionariesAdapter.getSelectors().selectById(state.dictionaries, dictionaryId);
      if (dictionary) {
        const values = [...dictionary.values];
        values.push({ id: valueId, name: valueName });
        const newDictionary = {
          ...dictionary,
          values,
        };
        dictionariesAdapter.updateOne(state.dictionaries, { id: dictionaryId, changes: newDictionary });
      }
    },
    updateValueInDictionary: (state, action) => {
      const { dictionaryId, valueId, valueName } = action.payload;
      const dictionary = dictionariesAdapter.getSelectors().selectById(state.dictionaries, dictionaryId);
      if (dictionary) {
        const newDictionary = {
          ...dictionary,
          values: dictionary.values.map((item) => {
            const value = {
              ...item,
            };
            if (item.id === valueId) {
              value.name = valueName;
            }
            return value;
          }),
        };
        dictionariesAdapter.updateOne(state.dictionaries, { id: dictionaryId, changes: newDictionary });
      }
    },
    deleteValueInDictionary: (state, action) => {
      const { dictionaryId, valueId } = action.payload;
      const dictionary = dictionariesAdapter.getSelectors().selectById(state.dictionaries, dictionaryId);
      if (dictionary) {
        const newDictionary = {
          ...dictionary,
          values: dictionary.values.filter((item) => item.id !== valueId),
        };
        dictionariesAdapter.updateOne(state.dictionaries, { id: dictionaryId, changes: newDictionary });
      }
    },
  },
});

export const { deleteValueInDictionary, updateValueInDictionary, addValueInDictionary, addDictionaries } =
  dictionarySlice.actions;

export const dictionariesSelector = () => dictionariesAdapter.getSelectors((state) => state.dictionaries.dictionaries);

export default dictionarySlice.reducer;
