import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GRID_PROPERTY } from 'consts';
import * as endpoint from 'helpers/api/endpoints';
import { getGridName } from 'module/grid/helper';

export const getMatchingFields = createAsyncThunk(
  'matchQuestionsAndOffers/getFields',
  async ({ type, grid }, { rejectWithValue }) => {
    const func = grid === GRID_PROPERTY ? endpoint.getMatchOTQFields : endpoint.getMatchQTOFields;
    return await func(
      type,
      (response) => ({ data: response?.data, grid }),
      (error) => rejectWithValue({ error }),
    );
  },
);
export const sendMatchingData = createAsyncThunk(
  'matchQuestionsAndOffers/sendMatchFields',
  async ({ offer, question, type, data, cb, grid }, { rejectWithValue }) => {
    const func = grid === GRID_PROPERTY ? endpoint.sendMatchOTQ : endpoint.sendMatchQTO;
    const id = grid === GRID_PROPERTY ? offer : question;
    return await func(
      id,
      type,
      data,
      (res) => {
        cb();
        return { data, type, offer, rows: res?.data, grid };
      },
      (error) => {
        cb();
        return rejectWithValue({ error });
      },
    );
  },
);
const defaultData = {
  property: {
    fields: [],
    savedFields: {},
    rows: null,
  },
  question: {
    fields: [],
    savedFields: {},
    rows: null,
  },
  isLoading: false,
  postLoading: false,
  changeGrid: null,
};

export const matchOffersAndQuestions = createSlice({
  name: 'matchOffersAndQuestions',
  initialState: defaultData,
  reducers: {
    resetMatchingOffersAndQuestions: (state, action) => {
      state[getGridName(action.payload.grid)].rows = null;
    },
    changeGrid: (state, action) => {
      state.changeGrid = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMatchingFields.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMatchingFields.fulfilled, (state, action) => {
      state.isLoading = false;
      state[getGridName(action.payload.grid)].fields = action?.payload.data;
    });
    builder.addCase(getMatchingFields.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(sendMatchingData.pending, (state) => {
      state.postLoading = true;
    });
    builder.addCase(sendMatchingData.fulfilled, (state, action) => {
      state.postLoading = false;
      const { data, offer, rows, type, grid } = action?.payload;
      const gridName = getGridName(grid);
      state[gridName].rows = rows;
      state[gridName].savedFields = {
        [type]: {
          offer,
          data,
        },
      };
      if (
        Number(localStorage.getItem('gridDisplayType')) === 1 ||
        window.matchMedia('(max-width: 768px), (max-height: 600px)').matches
      )
        state.changeGrid = true;
    });
    builder.addCase(sendMatchingData.rejected, (state, action) => {
      state.postLoading = false;
    });
  },
});

export const matchingDataSelector = (state, grid) => {
  const { isLoading, postLoading } = state.matchOffersAndQuestions;
  return { ...state.matchOffersAndQuestions[getGridName(grid)], isLoading, postLoading };
};
export const matchingRowsSelector = (state, grid) => state.matchOffersAndQuestions[getGridName(grid)].rows;
export const shouldChangeGrid = (state) => state.matchOffersAndQuestions.changeGrid;

export const { resetMatchingOffersAndQuestions, changeGrid } = matchOffersAndQuestions.actions;

export default matchOffersAndQuestions.reducer;
