import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getGridName } from '../../module/grid/helper';
import { GRID_PROPERTY, GRID_QUESTION } from '../../consts';
import * as crud from './crud';
import { v4 } from 'uuid';
import merge from 'deepmerge';
import * as endpoint from '../../helpers/api/endpoints';
import { normalizerToApi } from './normalizer';
import { loadDataGridByFilter } from '../gridSlice';

export const initQuery = (grid, filter = null) => {
  const init = {
    property: {
      id: v4(),
      filter,
      property: {
        normal: true,
      },
    },
    question: {
      id: v4(),
      filter,
      question: {
        normal: true,
      },
    },
  };

  return init[grid];
};

const add = (state, grid, filter) => {
  const gridNameQuery = getQueryName(grid);
  const arrayMerge = (_, sourceArray) => {
    return sourceArray;
  };
  state[gridNameQuery] = merge(state[gridNameQuery], filter, { arrayMerge });
};

export const getQueryName = (grid) => `${grid.toLowerCase()}Query`;

export const prepareGlobalPropertyFilter = createAsyncThunk(
  'globalFilterForm/prepareGlobalPropertyFilter',
  async (_, { rejectWithValue, getState, requestId }) => {
    if (getState().globalFilterForm.settings.currentRequestId !== requestId) {
      return;
    }
    return await endpoint.filterSettings(
      (data) => {
        return { ...data.data };
      },
      (error) => {
        return rejectWithValue(JSON.stringify(error));
      },
    );
  },
);
const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

export const loadDataGridFromGlobalFilter = createAsyncThunk(
  'globalFilterForm/loadDataGridFromGlobalFilter',
  async (value, { getState, dispatch }) => {
    const { grid, filter } = value;
    const state = getState().globalFilterForm[getQueryName(grid)];
    const query = normalizerToApi(merge(state, filter, { arrayMerge: overwriteMerge }));
    const filterEmpty = JSON.stringify(query[grid.toLowerCase()]) === '{"normal":true}';
    dispatch(loadDataGridByFilter({ grid, filter: query, filterEmpty }));
    return value;
  },
);

export const globalFilterReset = createAsyncThunk('globalFilterForm/resetFilterGrid', async (value, { dispatch }) => {
  const { grid } = value;
  const filter = initQuery(getGridName(grid));
  dispatch(loadDataGridByFilter({ grid, filter }));
  return value;
});

export const globalFilterFormSlice = createSlice({
  name: 'globalFilterForm',
  initialState: {
    propertyQuery: initQuery(getGridName(GRID_PROPERTY)),
    questionQuery: initQuery(getGridName(GRID_QUESTION)),
    settings: {
      names: {
        property: crud.initFilterNames,
        question: crud.initFilterNames,
      },
      isLoading: false,
      currentRequestId: undefined,
      data: null,
    },
  },
  reducers: {
    addToGlobalFiltersForms: (state, action) => {
      const {
        payload: { grid, filter },
      } = action;
      add(state, grid, filter);
    },
    clearGlobalFiltersForms: (state, action) => {
      const { grid, filter } = action.payload;
      const gridName = getGridName(grid);
      state[getQueryName(grid)] = initQuery(getGridName(grid), filter);
      state.settings.names[gridName].wasDelete = false;
      state.settings.names[gridName].wasSave = false;
    },
    setWasSave: (state, action) => {
      const { grid, wasSave } = action.payload;
      const gridName = getGridName(grid);
      state.settings.names[gridName].wasSave = wasSave;
    },
    setWasDelete: (state, action) => {
      const { grid, wasDelete } = action.payload;
      const gridName = getGridName(grid);
      state.settings.names[gridName].wasDelete = wasDelete;
    },
    setFilterError: (state, action) => {
      const { grid, error } = action.payload;
      const gridName = getGridName(grid);
      state.settings.names[gridName].error = error;
    },
  },
  extraReducers: (builder) => {
    crud.caseBuilder(builder);
    builder.addCase(loadDataGridFromGlobalFilter.fulfilled, (state, action) => {
      const {
        payload: { grid, filter },
      } = action;
      add(state, grid, filter);
    });
    builder.addCase(globalFilterReset.fulfilled, (state, action) => {
      const {
        payload: { grid },
      } = action;
      state[getQueryName(grid)] = initQuery(getGridName(grid));
    });

    builder.addCase(prepareGlobalPropertyFilter.pending, (state, action) => {
      state.settings.isLoading = true;
      if (!state.settings.currentRequestId) {
        state.settings.currentRequestId = action.meta.requestId;
      }
    });
    builder.addCase(prepareGlobalPropertyFilter.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        state.settings.data = action.payload;
        state.settings.isLoading = false;
        state.settings.currentRequestId = undefined;
      }
    });
    builder.addCase(prepareGlobalPropertyFilter.rejected, (state) => {
      state.settings.isLoading = false;
      state.settings.currentRequestId = undefined;
    });
  },
});

export const { addToGlobalFiltersForms, clearGlobalFiltersForms, setWasSave, setWasDelete, setFilterError } =
  globalFilterFormSlice.actions;

export const globalFormFilterQuerySelector = (state, grid) => {
  return state.globalFilterForm[getQueryName(grid)];
};
export const globalFilterSettingsSelector = (state) => state.globalFilterForm.settings;

export const globalFilterNameSelector = (state, grid) => {
  return state.globalFilterForm[getQueryName(grid)].filter;
};
export const globalFilterIdSelector = (state, grid) => {
  return state.globalFilterForm[getQueryName(grid)].id;
};

export default globalFilterFormSlice.reducer;
