import { createSlice } from '@reduxjs/toolkit';

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    name: null,
    open: false,
  },
  reducers: {
    sidebarAction: (state, action) => {
      state.open = action.payload.open;
      state.name = action.payload.name;
    },
  },
});

export const { sidebarAction } = sidebarSlice.actions;

export const sidebarSelector = (state) => state.sidebar;

export default sidebarSlice.reducer;
