import * as Sentry from '@sentry/react';

const useImoErrorHandler = () => {
  const errorHandleQuiet = (error) => {
    console.error(error);
    if (error && error.stack && error.message) {
      Sentry.captureException(error);
    } else {
      const exception = error?.error || error?.error_description || error?.message || error?.originalError || error;
      Sentry.captureMessage(exception);
    }
  };

  return { errorHandleQuiet };
};

export default useImoErrorHandler;
