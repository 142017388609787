import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as endpoint from 'helpers/api/endpoints';

const fixDefaultStatus = (status, currentState, state) => {
  if (status.default && currentState) {
    const keys = Object.keys(currentState.entities);
    const entities = keys.reduce((obj, i) => {
      const item = Object.assign({}, currentState.entities[i]);
      if (item.forProperties === status.forProperties && item.default) {
        item.default = false;
      }
      obj[i] = item;
      return obj;
    }, {});
    state.statuses.entities = entities;
  }
};
const normalizer = (statuses) => {
  const replaceColor = (status) => {
    let replace = ['0x', '#'];
    if (status.color.startsWith('#')) {
      replace = ['#', '0x'];
    }
    return { ...status, color: status.color.replace(replace[0], replace[1]) };
  };

  if (Array.isArray(statuses)) {
    return statuses.map((status) => replaceColor(status));
  }

  return replaceColor(statuses);
};

export const create = createAsyncThunk('status/create', async (status, { getState, rejectWithValue }) => {
  return await endpoint.createStatuses(
    normalizer(status),
    (payload) => {
      return { status: { ...status, id: payload.data.id }, currentState: getState()?.status?.statuses };
    },
    (error) => rejectWithValue({ error }),
  );
});

export const update = createAsyncThunk('status/update', async (status, { getState, rejectWithValue }) => {
  return await endpoint.updateStatuses(
    normalizer(status),
    () => {
      return { status, currentState: getState()?.status?.statuses };
    },
    (error) => rejectWithValue({ error }),
  );
});

export const deleteStatus = createAsyncThunk('status/delete', async (id, { rejectWithValue }) => {
  return await endpoint.deleteStatuses(
    id,
    () => {
      return { id };
    },
    (error) => rejectWithValue({ error }),
  );
});

const statusAdapter = createEntityAdapter({
  selectId: (status) => status.id,
  sortComparer: (statusA, statusB) => statusA.name.localeCompare(statusB.name),
});

const init = {
  isLoading: false,
  statuses: statusAdapter.getInitialState(),
};

export const statusSlice = createSlice({
  name: 'status',
  initialState: init,
  reducers: {
    addStatuses: (state, action) => {
      statusAdapter.setAll(state.statuses, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(create.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(create.fulfilled, (state, action) => {
      if (action.payload) {
        const { status, currentState } = action.payload;
        fixDefaultStatus(status, currentState, state);
        statusAdapter.addOne(state.statuses, status);
      }
      state.isLoading = false;
    });
    builder.addCase(create.rejected, (state) => {
      state.isLoading = false;
      //TODO obsługa błędów
    });
    builder.addCase(update.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(update.fulfilled, (state, action) => {
      if (action.payload) {
        const { status, currentState } = action.payload;
        fixDefaultStatus(status, currentState, state);
        statusAdapter.updateOne(state.statuses, { id: status.id, changes: status });
      }
      state.isLoading = false;
    });
    builder.addCase(update.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action);
      //TODO obsługa błędów
    });
    builder.addCase(deleteStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteStatus.fulfilled, (state, action) => {
      const { id } = action.payload;
      statusAdapter.removeOne(state.statuses, id);
      state.isLoading = false;
    });
    builder.addCase(deleteStatus.rejected, (state) => {
      state.isLoading = false;
      //TODO obsługa błędów
    });
  },
});

export const { addStatuses } = statusSlice.actions;

export const statusesSelector = () => statusAdapter.getSelectors((state) => state.status.statuses);
export const statusesLoadingSelector = (state) => state.status.isLoading;

export default statusSlice.reducer;
