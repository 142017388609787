import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Icon = styled.i`
  color: ${(props) => props.color};
  margin: ${(props) => props.margin};
  font-size: ${(props) => props.size} !important;
`;

const IMOIcon = (props) => {
  const { className, name, ...rest } = props;
  const classes = classNames('icon', `icon-${name}`, className);

  return <Icon className={classes} {...rest} />;
};

IMOIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  margin: PropTypes.string,
};

export default IMOIcon;
