import { useSelector } from 'react-redux';
import {
  Admin,
  CRED_SELF_OFFERS_ADD_READ,
  CRED_BRANCH_OFFERS_ADD_READ,
  CRED_ALL_OFFERS_ADD_READ,
  CRED_SELF_QUESTIONS_ADD_READ,
  CRED_BRANCH_QUESTIONS_ADD_READ,
  CRED_ALL_QUESTIONS_ADD_READ,
  SuperAdmin,
  CRED_SELF_CUSTOMER_ADD_EDIT_READ,
  CRED_BRANCH_CUSTOMER_ADD_EDIT_READ,
  CRED_ALL_CUSTOMER_ADD_EDIT_READ,
  CRED_SELF_CUSTOMER_DELETE,
  CRED_BRANCH_CUSTOMER_DELETE,
  CRED_ALL_CUSTOMER_DELETE,
} from 'helpers/permissions';
import { userSelector } from '../redux/userSlice';

export const useUser = () => {
  const user = useSelector(userSelector);
  const permissions = user?.permissions || [];
  const hasCredentials = (...credentials) => {
    if (permissions.includes(Admin) || permissions.includes(SuperAdmin)) {
      return true;
    }
    const hasCredentials = credentials.filter((val) => {
      return permissions.includes(val);
    });
    return hasCredentials.length === credentials.length;
  };
  const canAddReadOffer =
    hasCredentials(CRED_SELF_OFFERS_ADD_READ) ||
    hasCredentials(CRED_BRANCH_OFFERS_ADD_READ) ||
    hasCredentials(CRED_ALL_OFFERS_ADD_READ);

  const canAddReadQuestion =
    hasCredentials(CRED_SELF_QUESTIONS_ADD_READ) ||
    hasCredentials(CRED_BRANCH_QUESTIONS_ADD_READ) ||
    hasCredentials(CRED_ALL_QUESTIONS_ADD_READ);

  const canAccessOwnersSeekers =
    hasCredentials(CRED_SELF_CUSTOMER_ADD_EDIT_READ) ||
    hasCredentials(CRED_BRANCH_CUSTOMER_ADD_EDIT_READ) ||
    hasCredentials(CRED_ALL_CUSTOMER_ADD_EDIT_READ) ||
    hasCredentials(CRED_SELF_CUSTOMER_DELETE) ||
    hasCredentials(CRED_BRANCH_CUSTOMER_DELETE) ||
    hasCredentials(CRED_ALL_CUSTOMER_DELETE);

  const canAddEditReadOwnerSeeker =
    hasCredentials(CRED_SELF_CUSTOMER_ADD_EDIT_READ) ||
    hasCredentials(CRED_BRANCH_CUSTOMER_ADD_EDIT_READ) ||
    hasCredentials(CRED_ALL_CUSTOMER_ADD_EDIT_READ);

  const isSuperAdmin = permissions.includes(SuperAdmin);
  return {
    user,
    hasCredentials,
    isSuperAdmin,
    canAddReadOffer,
    canAddReadQuestion,
    canAccessOwnersSeekers,
    canAddEditReadOwnerSeeker,
  };
};
