import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider } from 'react-redux';
import store from './store';
import * as Sentry from '@sentry/react';
import { apiUrl } from './helpers/api/routes';

Sentry.init({
  dsn: process.env.NODE_ENV === 'production' ? 'https://f4f887e08f6f4fe08fdb2e15b829c5d7@sentry.possible.pl/3' : '',
  allowUrls: [/https:\/\/s[0-9]+\.imo\.pl/],
  initialScope: {
    tags: {
      server: window.location.host,
      agency: window.location.pathname.replace('/', ''),
    },
  },
  tunnel: apiUrl('v1/errors'),
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
  integrations: [new Sentry.Replay()],
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

serviceWorker.unregister();
