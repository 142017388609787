import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { tagPropType } from 'utils/helper.js';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';

const Loader = styled(Spinner)`
  position: absolute;
  top: calc(50% - 0.75rem);
  right: calc(50% - 0.75rem);
  width: 1.5rem;
  height: 1.5rem;
`;

const IMOButton = (props) => {
  let {
    tag: Tag,
    className,
    innerRef,
    onClick,
    disabled,
    active,
    block,
    color,
    size,
    pressed,
    shape,
    variant,
    icon,
    loading,
    ...attributes
  } = props;

  const click = (e) => !disabled && onClick && onClick(e);

  const isLink = attributes.to || attributes.href;

  //render
  const classes = classNames(
    className,
    'btn',
    'btn-imo',
    variant || color ? `btn${variant ? '-' + variant : ''}-imo-${color}` : false,
    size ? `btn-${size}` : false,
    block ? 'btn-block' : false,
    shape ? `btn-${shape}` : false,
    pressed ? 'btn-pressed' : false,
    icon ? 'btn-icon' : false,
    {
      active: active && !isLink,
      disabled: disabled && !isLink,
    },
  );

  return (
    <Tag
      className={classes}
      type="button"
      disabled={disabled || loading}
      {...attributes}
      onClick={click}
      ref={innerRef}
    >
      {attributes.children}
      {loading && <Loader />}
    </Tag>
  );
  // }
};

IMOButton.propTypes = {
  tag: tagPropType,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  active: PropTypes.bool,
  block: PropTypes.bool,
  loading: PropTypes.bool,
  shape: PropTypes.string,
  variant: PropTypes.oneOf(['', 'ghost', 'outline', 'thirty']),
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  pressed: PropTypes.bool,
  icon: PropTypes.bool,
};

IMOButton.defaultProps = {
  tag: 'button',
};

//export
export default IMOButton;
