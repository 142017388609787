import { configureStore } from '@reduxjs/toolkit';
import userReducer from './redux/userSlice';
import broadcastChannelReducer from './redux/broadcastChannelSlice';
import categoryReducer from './redux/categorySlice';
import headerModalReducer from './redux/modalSlice';
import sidebarReducer from './redux/sidebarSlice';
import gridReducer from './redux/gridSlice';
import globalFilterFormReducer from './redux/gridFilterForm/slice';
import dictionaryReducer from './redux/dictionarySlice';
import languagesReducer from './redux/languagesSlice';
import statusReducer from './redux/statusSlice';
import matchOffersAndQuestionsReducer from './redux/matchOffersAndQuestionsSlice';
import featureFlagsReducer from './redux/featureFlagsSlice';
import notificationSliceReducer from './redux/notificationSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    category: categoryReducer,
    headerModal: headerModalReducer,
    sidebar: sidebarReducer,
    grid: gridReducer,
    globalFilterForm: globalFilterFormReducer,
    dictionaries: dictionaryReducer,
    languages: languagesReducer,
    status: statusReducer,
    broadcastChannel: broadcastChannelReducer,
    matchOffersAndQuestions: matchOffersAndQuestionsReducer,
    featureFlags: featureFlagsReducer,
    notifications: notificationSliceReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
