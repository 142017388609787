import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getStoredToken, logout } from 'helpers/auth';
import * as endpoint from 'helpers/api/endpoints';
import { addDictionaries } from './dictionarySlice';
import { addStatuses } from './statusSlice';
import useImoErrorHandler from '../module/errorHandler/useImoErrorHandler';
import { addLanguages } from './languagesSlice';
import jwtDecode from 'jwt-decode';
import { addFeatureFlags } from './featureFlagsSlice';
import * as Sentry from '@sentry/react';

const formatDuplicates = (items) => {
  return items.reduce((obj, i) => {
    obj[i.categoryId] = i.checked;
    return obj;
  }, {});
};
const formatInvestments = (investments) => {
  if (!investments) return [];
  const investmentsKeys = Object.keys(investments);
  const fixedInvestments =
    investmentsKeys.length > 0 ? investmentsKeys.map((i) => ({ id: Number(i), name: investments[i] })) : [];
  return fixedInvestments;
};

export const userValid = createAsyncThunk('user/userValid', async (_, { dispatch }) => {
  const token = getStoredToken();
  if (token) {
    const jwt = jwtDecode(token);
    if (process.env.NODE_ENV === 'development' || jwt?.agency === window.location.pathname?.replace('/', '')) {
      const response = await endpoint.userSettings(
        (res) => res,
        (error) => {
          logout();
          useImoErrorHandler().errorHandleQuiet(error);
          throw Error(error);
        },
      );
      const investments = formatInvestments(response.data.investments);
      dispatch(addDictionaries(response.data.dictionaries));
      dispatch(addStatuses(response.data.statuses));
      dispatch(addLanguages(response.data.languages));
      dispatch(addFeatureFlags(response.data.featureFlags));
      // TODO: usunąć jak będzie połączenie przygotowane api
      let gridDisplayType = Number(localStorage.getItem('gridDisplayType'));
      if (!gridDisplayType) {
        gridDisplayType = 1;
        localStorage.setItem('gridDisplayType', gridDisplayType);
      }
      const duplicates = {
        properties: formatDuplicates(response.data.duplicates.properties),
        questions: formatDuplicates(response.data.duplicates.questions),
      };

      Sentry.setUser({
        id: response.data.agent.id,
        username: response.data.agent.login,
        email: response.data.agent.email,
      });

      return {
        ...response.data.agent,
        permissions: response.data.permissions,
        showDisabledExports: response.data.unreadDisabledExportReportExists,
        gridDisplayType,
        duplicates,
        investments,
        agencyColor: response.data?.agencyColor,
        instanceSize: response.data?.instanceSize,
      };
    }
  }
  logout();
});
const storedTheme = localStorage.getItem('theme');

const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

const defaultDark = storedTheme === 'dark' || (storedTheme === null && prefersDark);

const screen = (resize) => ({
  width: window.screen.width,
  height: window.screen.height,
  isMobile: window.matchMedia('(max-width: 768px), (max-height: 600px)').matches,
  isMobileHeader: window.matchMedia('(max-width: 1278px), (max-height: 600px)').matches,
  resize: resize,
});

if (defaultDark) {
  localStorage.setItem('theme', 'dark');
  document.documentElement.setAttribute('data-theme', 'dark');
}
export const userSlice = createSlice({
  name: 'user',
  initialState: {
    photo: null,
    gridDisplayType: 1,
    theme: defaultDark ? 'dark' : 'light',
    screen: screen(0),
  },
  reducers: {
    updateGridDisplayType: (state, action) => {
      state.gridDisplayType = Number(action.payload);
    },
    updateTheme: (state, action) => {
      state.theme = action.payload;
    },
    updateInvestments: (state, action) => {
      state.investments = formatInvestments(action.payload);
    },
    updateScreen: (state, action) => {
      state.screen = screen(state.screen.resize + 1);
    },
    updateDuplicates: (state, action) => {
      state.duplicates = {
        properties: formatDuplicates(action.payload.properties),
        questions: formatDuplicates(action.payload.questions),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userValid.fulfilled, (state, action) => {
      return { ...state, ...action.payload };
    });
  },
});

export const { updateGridDisplayType, updateTheme, updateDuplicates, updateInvestments, updateScreen } =
  userSlice.actions;
export const userSelector = (state) => state.user;
export const themeSelector = (state) => state.user.theme;
export const duplicatesSelector = (state) => state.user.duplicates;
export const investmentsSelector = (state) => state.user.investments;

export const screenSelector = (state) => state.user.screen;

export default userSlice.reducer;
