import { createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { getGridName } from 'module/grid/helper';
import * as endpoint from 'helpers/api/endpoints';
import merge from 'deepmerge';
import { addToGlobalFiltersForms, clearGlobalFiltersForms, getQueryName } from './slice';
import { notEmpty } from 'helpers/utils';
import { v4 } from 'uuid';
import { normalizerFromApi, normalizerToApi } from './normalizer';

export const fetchFiltersName = createAsyncThunk(
  'globalFilterForm/fetchFiltersName',
  async (value, { getState, rejectWithValue }) => {
    const categoryId = getState().category.id;
    const gridName = getGridName(value.grid);
    const wasFetch = getState().globalFilterForm.settings.names[gridName].wasFetch;

    if (wasFetch) {
      return;
    }

    return await endpoint.getFilterNames(
      gridName,
      categoryId,
      (payload) => {
        return {
          filters: payload.data,
        };
      },
      (error) => rejectWithValue({ grid: gridName, error }),
    );
  },
);

export const createOrUpdateFilterName = createAsyncThunk(
  'globalFilterForm/createOrUpdateFilterName',
  async (value, { getState, rejectWithValue }) => {
    const { grid, name, filterId } = value;
    const categoryId = getState().category.id;
    const gridName = getGridName(grid);

    const state = getState().globalFilterForm[getQueryName(grid)];
    const query = normalizerToApi(merge(state, {}));

    const filter = {
      name,
      query,
    };

    if (filterId) {
      return await endpoint.updateFilter(
        filterId,
        filter,
        () => {
          return { grid, filter: { id: filterId, name } };
        },
        (error) => {
          return rejectWithValue({ grid: gridName, ...error, name });
        },
      );
    }

    return await endpoint.createFilter(
      gridName,
      categoryId,
      filter,
      (data) => {
        return { grid, filter: { id: data.id, name } };
      },
      (error) => {
        return rejectWithValue({ grid: gridName, ...error, name });
      },
    );
  },
);

export const deleteFilterName = createAsyncThunk(
  'globalFilterForm/deleteFilterName',
  async (value, { rejectWithValue, dispatch }) => {
    const { grid, id } = value;
    return await endpoint.deleteFilter(
      id,
      () => {
        dispatch(clearGlobalFiltersForms({ grid }));
        return value;
      },
      (error) => {
        return rejectWithValue(error);
      },
    );
  },
);

export const loadFilter = createAsyncThunk(
  'globalFilterForm/loadFilter',
  async (value, { dispatch, rejectWithValue }) => {
    const { grid, id } = value;

    return await endpoint.loadFilter(
      id,
      async (payload) => {
        dispatch(clearGlobalFiltersForms({ grid }));
        if (notEmpty(payload.data?.query)) {
          const query = await normalizerFromApi(payload.data.query);
          dispatch(addToGlobalFiltersForms({ grid, filter: query }));
        }
        return { grid, id };
      },
      (error) => rejectWithValue(JSON.stringify(error)),
    );
  },
);

export const namesAdapter = createEntityAdapter({
  selectId: (filter) => filter.id,
  sortComparer: (filterA, filterB) => filterA.name.localeCompare(filterB.name),
});

export const initFilterNames = {
  wasSave: false,
  wasDelete: false,
  isLoading: false,
  wasFetch: false,
  names: namesAdapter.getInitialState({ error: null }),
};

export const caseBuilder = (builder) => {
  builder.addCase(fetchFiltersName.pending, (state, action) => {
    const grid = getGridName(action.meta.arg.grid);
    state.settings.names[grid].isLoading = true;
  });
  builder.addCase(fetchFiltersName.fulfilled, (state, action) => {
    const grid = getGridName(action.meta.arg.grid);
    if (action.payload) {
      const { filters } = action.payload;
      state.settings.names[grid].wasFetch = true;
      namesAdapter.setAll(state.settings.names[grid].names, filters);
    }
    state.settings.names[grid].isLoading = false;
  });
  builder.addCase(fetchFiltersName.rejected, (state, action) => {
    const grid = getGridName(action.meta.arg.grid);
    state.settings.names[grid].isLoading = false;
    state.settings.names[grid].wasFetch = false;
    console.error(action.error);
  });

  builder.addCase(deleteFilterName.pending, (state, action) => {
    state.settings.isLoading = true;
    state.settings.names[getGridName(action.meta.arg.grid)].wasDelete = false;
  });
  builder.addCase(deleteFilterName.fulfilled, (state, action) => {
    const { grid, id } = action.payload;
    const gridName = getGridName(grid);
    state.settings.isLoading = false;
    state.settings.names[gridName].wasDelete = true;
    namesAdapter.removeOne(state.settings.names[gridName].names, id);
  });
  builder.addCase(deleteFilterName.rejected, (state, action) => {
    console.error(action);
    state.settings.isLoading = false;
    state.settings.names[getGridName(action.meta.arg.grid)].wasDelete = false;
  });

  builder.addCase(loadFilter.pending, (state) => {
    state.settings.isLoading = true;
  });
  builder.addCase(loadFilter.fulfilled, (state, action) => {
    const { grid, id } = action.payload;
    state.settings.isLoading = false;
    state[getQueryName(grid)].filter = namesAdapter
      .getSelectors()
      .selectById(state.settings.names[getGridName(grid)].names, id);
    state[getQueryName(grid)].id = v4();
  });
  builder.addCase(loadFilter.rejected, (state, action) => {
    state.settings.isLoading = false;
    console.error(action);
  });

  builder.addCase(createOrUpdateFilterName.pending, (state, action) => {
    state.settings.isLoading = true;
    state.settings.names[getGridName(action.meta.arg.grid)].wasSave = false;
  });
  builder.addCase(createOrUpdateFilterName.fulfilled, (state, action) => {
    const { grid, filter } = action.payload;
    const gridName = getGridName(grid);
    state.settings.isLoading = false;
    const names = state.settings.names[gridName];
    state.settings.names[gridName].wasSave = true;
    namesAdapter.upsertOne(names.names, filter);
    state[getQueryName(grid)].filter = namesAdapter
      .getSelectors()
      .selectById(state.settings.names[gridName].names, filter.id);
  });
  builder.addCase(createOrUpdateFilterName.rejected, (state, action) => {
    state.settings.isLoading = false;
    const {
      payload: { code, name } = {},
      meta: {
        arg: { grid },
      },
    } = action;
    state.settings.names[getGridName(grid)].wasSave = false;
    if (code) {
      state.settings.names[getGridName(grid)].error = `Filtr o nazwie '${name}' już istnieje.`;
    }

    console.error(action);
  });
};

export const globalFilterSettingsNamesSelector = (grid) =>
  namesAdapter.getSelectors((state) => state.globalFilterForm.settings.names[getGridName(grid)].names);

export const globalFilterSettingsNamesStateSelector = (state, grid) =>
  state.globalFilterForm.settings.names[getGridName(grid)];

export const globalFilterSettingsNamesErrorSelector = (state, grid) =>
  state.globalFilterForm.settings.names[getGridName(grid)].error;
