import { createSlice } from '@reduxjs/toolkit';

export const broadcastChannelSlice = createSlice({
  name: 'broadcastChannel',
  initialState: {
    broadcastChannel: new BroadcastChannel('main_channel'),
  },
  reducers: {},
});

export const broadcastChannelSelector = (state) => state.broadcastChannel;

export default broadcastChannelSlice.reducer;
