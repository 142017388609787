import { AGENCY_NAME, SERVER_URL } from '../consts';

export const url = process.env.REACT_APP_URL || SERVER_URL + AGENCY_NAME;

export const getStoredToken = () => localStorage.getItem('imo-token') || process.env.REACT_APP_TOKEN;

export const logout = () => {
  const fetchUrl = url + `/_login/api/v1/logout`;
  fetch(fetchUrl, {
    method: 'GET',
    headers: {
      authorization: 'Bearer ' + getStoredToken(),
      'Content-type': 'application/json; charset=utf-8',
    },
  }).finally(() => {
    localStorage.removeItem('imo-token');
    let href = url + '/_login/?back=' + btoa(window.location.href);
    window.location.href = href;
  });
};

export const loginToAgentAccount = (agentId) => {
  const fetchUrl = url + `/_login/api/v1/login/backdoor/${agentId}`;
  fetch(fetchUrl, {
    method: 'GET',
    headers: {
      authorization: 'Bearer ' + getStoredToken(),
      'Content-type': 'application/json; charset=utf-8',
    },
  })
    .then((res) => {
      if (res.status === 200) {
        localStorage.removeItem('imo-token');
      }
      return res.json();
    })
    .then((value) => {
      localStorage.setItem('imo-token', value.data.token);
      window.location.reload(true);
    });
};
