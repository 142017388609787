import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CATEGORY_INVESTMENT, GRID_PROPERTY, GRID_QUESTION, MAX_CATEGORY_NUMBER } from 'consts';
import { gridFiltersReset, selectGridCheckboxReset, selectGridRowReset } from './gridSlice';
import { getCategory, getStoreCategory, setStoreCategory } from 'helpers/category';
import { globalFilterReset } from './gridFilterForm/slice';
import { categoryToText } from 'helpers/utils';

export const changeCategory = createAsyncThunk('category/changeCategory', async (category, { dispatch }) => {
  dispatch(selectGridRowReset({ grid: GRID_PROPERTY }));
  dispatch(selectGridRowReset({ grid: GRID_QUESTION }));
  dispatch(selectGridCheckboxReset({ grid: GRID_PROPERTY }));
  dispatch(selectGridCheckboxReset({ grid: GRID_QUESTION }));
  dispatch(gridFiltersReset({ grid: GRID_PROPERTY }));
  dispatch(gridFiltersReset({ grid: GRID_QUESTION }));
  dispatch(globalFilterReset({ grid: GRID_PROPERTY }));
  dispatch(globalFilterReset({ grid: GRID_QUESTION }));
  return category;
});

const setFormTitle = (url, name) => {
  const [categoryId, id] = url.split('/').slice(1);
  const isInvestment = parseInt(categoryId) === CATEGORY_INVESTMENT;
  const categoryName = categoryToText(parseInt(categoryId));
  id === '-1'
    ? (document.title = isInvestment ? 'Nowa Inwestycja' : `Nowa ${name.toLowerCase()} - ${categoryName}`)
    : (document.title = isInvestment ? `Inwestycja ${id}` : `${name} ${id} ${categoryName}`);
};

const initCategory = () => {
  const storeCategoryId = getStoreCategory();
  let init = getCategory(4);

  if (storeCategoryId !== null && storeCategoryId !== init.id) {
    init = getCategory(storeCategoryId);
  }
  const url = window.location.href;
  // jeżeli MAX_CATEGORY_NUMBER będzie dwucyfrowy, np., jeżeli to będzie 12, to powinno wyglądać ([0-9]|1[0-2])
  // eslint-disable-next-line
  const offerQuestionRegex = new RegExp(`/[0-${MAX_CATEGORY_NUMBER}]|14\/(\\d+|-1)`);
  const offerUrlToTest = url.split('#/offer')[1];
  const questionUrlToTest = url.split('#/question')[1];
  if (offerUrlToTest && offerQuestionRegex.test(offerUrlToTest)) setFormTitle(offerUrlToTest, 'Oferta');
  else if (questionUrlToTest && offerQuestionRegex.test(questionUrlToTest)) {
    setFormTitle(questionUrlToTest, 'Zapytanie');
  } else {
    document.title = init.name + ' - IMO';
  }

  return init;
};

export const categorySlice = createSlice({
  name: 'category',
  initialState: initCategory(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changeCategory.fulfilled, (state, action) => {
      const {
        payload: { id, investmentCategory },
      } = action;
      const category = investmentCategory || getCategory(id);
      document.title = category.name + ' - IMO';
      setStoreCategory(investmentCategory ? CATEGORY_INVESTMENT : id);
      return category;
    });
  },
});

export const categorySelector = (state) => state.category;

export default categorySlice.reducer;
