// Nowe uprawnienia
export const Admin = -1;
export const Brak = 0;
export const SuperAdmin = -2;
/**
 * Oferty
 */
/**
 * Dodawanie, odczyt ofert
 * Grupa: Własne
 * To uprawnienie jest zawsze włączone
 */
export const CRED_SELF_OFFERS_ADD_READ = 5000;

/**
 * Dodawanie, odczyt ofert
 * Grupa: Filie
 */
export const CRED_BRANCH_OFFERS_ADD_READ = 5001;

/**
 * Dodawanie, odczyt ofert
 * Grupa: Wszystkie
 */
export const CRED_ALL_OFFERS_ADD_READ = 5002;

/**
 * Edycja ofert
 * Grupa: Własne
 * To uprawnienie jest zawsze włączone
 */
export const CRED_SELF_OFFERS_EDIT = 5003;

/**
 * Edycja ofert
 * Grupa: Filie
 */
export const CRED_BRANCH_OFFERS_EDIT = 5004;

/**
 * Edycja ofert
 * Grupa: Wszystkie
 */
export const CRED_ALL_OFFERS_EDIT = 5005;

/**
 * Usuwanie ofert
 * Grupa: Własne
 */
export const CRED_SELF_OFFERS_DELETE = 5006;

/**
 * Usuwanie ofert
 * Grupa: Filie
 */
export const CRED_BRANCH_OFFERS_DELETE = 5007;

/**
 * Usuwanie ofert
 * Grupa: Wszystkie
 */
export const CRED_ALL_OFFERS_DELETE = 5008;

/**
 * Archiwizowanie oferty
 * Grupa: Własne
 */
export const CRED_SELF_OFFERS_ARCHIVE = 5009;

/**
 * Archiwizowanie ofert
 * Grupa: Filie
 */
export const CRED_BRANCH_OFFERS_ARCHIVE = 5010;

/**
 * Archiwizowanie ofert
 * Grupa: Wszystkie
 */
export const CRED_ALL_OFFERS_ARCHIVE = 5011;

/**
 * Eksport oferty do internetu
 * Grupa: Własne
 */
export const CRED_SELF_OFFERS_EXPORT = 5012;

/**
 * Eksport oferty do internetu
 * Grupa: Filie
 */
export const CRED_BRANCH_OFFERS_EXPORT = 5013;

/**
 * Eksport oferty do internetu
 * Grupa: Wszystkie
 */
export const CRED_ALL_OFFERS_EXPORT = 5014;

/**
 * E-mail i wydruk oferty
 * Grupa: Własne
 */
export const CRED_SELF_OFFERS_PRINTS_EMAIL = 5015;

/**
 * E-mail i wydruk oferty
 * Grupa: Filie
 */
export const CRED_BRANCH_OFFERS_PRINTS_EMAIL = 5016;

/**
 * E-mail i wydruk oferty
 * Grupa: Wszystkie
 */
export const CRED_ALL_OFFERS_PRINTS_EMAIL = 5017;

/**
 * Właściciele
 */
/**
 * Dodawanie, edycja, odczyt właściciela
 * Grupa: Własne
 * To uprawnienie jest zawsze włączone
 */
export const CRED_SELF_CUSTOMER_ADD_EDIT_READ = 5018;

/**
 * Dodawanie, edycja, odczyt właściciela
 * Grupa: Filie
 */
export const CRED_BRANCH_CUSTOMER_ADD_EDIT_READ = 5019;

/**
 * Dodawanie, edycja, odczyt właściciela
 * Grupa: Wszystkie
 */
export const CRED_ALL_CUSTOMER_ADD_EDIT_READ = 5020;

/**
 * Usuwanie właściciela
 * Grupa: Własne
 */
export const CRED_SELF_CUSTOMER_DELETE = 5024;

/**
 * Usuwanie właściciela
 * Grupa: Filie
 */
export const CRED_BRANCH_CUSTOMER_DELETE = 5025;

/**
 * Usuwanie właściciela
 * Grupa: Wszystkie
 */
export const CRED_ALL_CUSTOMER_DELETE = 5026;

/**
 * ZAPYTANIA
 */
/**
 * Dodawanie, odczyt zapytań
 * Grupa: Własne
 * To uprawnienie jest zawsze włączone
 */
export const CRED_SELF_QUESTIONS_ADD_READ = 5027;

/**
 * Dodawanie, odczyt zapytań
 * Grupa: Filie
 */
export const CRED_BRANCH_QUESTIONS_ADD_READ = 5028;

/**
 * Dodawanie, odczyt zapytań
 * Grupa: Wszystkie
 */
export const CRED_ALL_QUESTIONS_ADD_READ = 5029;

/**
 * Edycja zapytań
 * Grupa: Własne
 * To uprawnienie jest zawsze włączone
 */
export const CRED_SELF_QUESTIONS_EDIT = 5030;

/**
 * Edycja zapytań
 * Grupa: Filie
 */
export const CRED_BRANCH_QUESTIONS_EDIT = 5031;

/**
 * Edycja zapytań
 * Grupa: Wszystkie
 */
export const CRED_ALL_QUESTIONS_EDIT = 5032;

/**
 * Usuwanie zapytań
 * Grupa: Własne
 */
export const CRED_SELF_QUESTIONS_DELETE = 5033;

/**
 * Usuwanie zapytań
 * Grupa: Filie
 */
export const CRED_BRANCH_QUESTIONS_DELETE = 5034;

/**
 * Usuwanie zapytań
 * Grupa: Wszystkie
 */
export const CRED_ALL_QUESTIONS_DELETE = 5035;

/**
 * Archiwizowanie zapytań
 * Grupa: Własne
 */
export const CRED_SELF_QUESTIONS_ARCHIVE = 5036;

/**
 * Archiwizowanie zapytań
 * Grupa: Filie
 */
export const CRED_BRANCH_QUESTIONS_ARCHIVE = 5037;

/**
 * Archiwizowanie zapytań
 * Grupa: Wszystkie
 */
export const CRED_ALL_QUESTIONS_ARCHIVE = 5038;

/**
 * E-mail i wydruk zapytań
 * Grupa: Własne
 */
export const CRED_SELF_QUESTIONS_PRINTS_EMAIL = 5039;

/**
 * E-mail i wydruk zapytań
 * Grupa: Filie
 */
export const CRED_BRANCH_QUESTIONS_PRINTS_EMAIL = 5040;

/**
 * E-mail i wydruk zapytań
 * Grupa: Wszystkie
 */
export const CRED_ALL_QUESTIONS_PRINTS_EMAIL = 5041;

/**
 * Pozostałe
 * Brak podziału na grupy, wszystkie uprawnienia traktowane są jakby należały do grupy Wszystkie
 */
/**
 * Faktury
 */
export const CRED_INVOICES = 5042;

/**
 * Ustawienia i administracja
 */
export const CRED_SETTINGS_ADMINISTRATION = 5043;

/**
 * Kalendarz dodawanie nowych wpisów innym agentom z możliwością ich edycji i usuwania
 */
export const CRED_CALENDAR_OTHER_ALL = 5044;

/**
 * Kalendarz edycja istniejących wpisów innych agentów
 */
export const CRED_CALENDAR_OTHER_EDIT = 5045;

/**
 * Kalendarz odczyt kalendarzy innych agentów
 */
export const CRED_CALENDAR_OTHER_READ = 5046;

/**
 * Zarządzanie dokumentami w repozytorium
 */
export const CRED_DOCUMENTS_REPO = 5047;

/**
 * Helpdesk widoczność wszystkich zgłoszeń
 */
export const CRED_HELPDESK_ALL = 5048;

/**
 * Profil możliwość edycji profilu
 */
export const CRED_PROFILE_EDIT = 5052;
