import { apiUrl } from './routes';
import { getStoredToken, logout } from '../auth';

const token = getStoredToken();
const defaults = {
  headers: () => ({
    'Content-Type': 'application/json',
    Authorization: token ? `Bearer ${token}` : undefined,
  }),
};

const api = (method, url, variables, signal = undefined) => {
  return new Promise((resolve, reject) => {
    fetch(apiUrl(url), {
      method,
      headers: defaults.headers(),
      body: method !== 'get' ? JSON.stringify(variables) : undefined,
      signal: signal,
    })
      .then((res) => {
        if (res.status === 401) {
          logout();
          throw new Error('Brak uprawnień!');
        }
        return res.json();
      })
      .then((value) => resolve(value))
      .catch((reason) => reject(reason));
  });
};

const retry = async (func, repeat) => {
  let resp = null;
  let error = null;
  for (let i = 0; i < repeat; i++) {
    try {
      error = null;
      resp = await func();
      break;
    } catch (e) {
      error = e;
    }
  }
  return new Promise((resolve, reject) => {
    if (error === null) {
      resolve(resp);
    } else {
      reject(error);
    }
  });
};

const service = {
  get: (url, signal = undefined) => api('GET', url, undefined, signal),
  post: (url, variables, signal = undefined) => api('POST', url, variables, signal),
  put: (...args) => api('PUT', ...args),
  patch: (...args) => api('PATCH', ...args),
  delete: (...args) => api('DELETE', ...args),

  retry: (func, repeat) => retry(func, repeat),
};

export default service;
