import { createSlice } from '@reduxjs/toolkit';

export const languagesSlice = createSlice({
  name: 'languages',
  initialState: {
    languages: {},
    languagesList: [],
  },
  reducers: {
    addLanguages: (state, action) => {
      const languagesList = Object.keys(action.payload).reduce((arr, i) => {
        arr.push({ value: i, label: action.payload[i] });
        return arr;
      }, []);
      return { languages: action.payload, languagesList };
    },
  },
});

export const { addLanguages } = languagesSlice.actions;

export default languagesSlice.reducer;
