import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gridResize } from 'redux/gridSlice';
import { GRID_PROPERTY } from 'consts';

const getFlexVal = (dataSite) => {
  const element = document.querySelector(`[data-site="${dataSite}"]`);
  if (element) {
    return element.style.flex?.split(' ')[0] || 1;
  }
  return 1;
};

export const getFixedGridSize = (type) => {
  switch (Number(type)) {
    case 1:
      return {
        left: {
          width: getFlexVal('left-grid-parent'),
        },
        right: {
          width: getFlexVal('right-grid-parent'),
        },
      };
    case 2:
      return {
        left: {
          width: getFlexVal('left-grid-parent'),
          top: { height: getFlexVal('left-grid-top-child') },
          bottom: { height: getFlexVal('left-grid-bottom-child') },
        },
        right: {
          width: getFlexVal('right-grid-parent'),
        },
      };

    case 3:
      return {
        left: {
          width: getFlexVal('left-grid-parent'),
          top: { height: getFlexVal('left-grid-top-child') },
          bottom: { height: getFlexVal('left-grid-bottom-child') },
        },
        right: {
          width: getFlexVal('right-grid-parent'),
          top: { height: getFlexVal('right-grid-top-child') },
          bottom: { height: getFlexVal('right-grid-bottom-child') },
        },
      };
    default:
      return {};
  }
};

export const useDashboard = () => {
  const { gridDisplayType } = useSelector((state) => state.user);
  const [dashboardType, setDashboardType] = useState(GRID_PROPERTY);
  const dispatch = useDispatch();

  const rightElementRef = useRef();
  const leftGridParentRef = useRef();
  const rightGridParentRef = useRef();
  const leftGridRef = useRef();
  const rightGridRef = useRef();

  const rightDetailRef = useRef();

  // tylko dla typu drugiego
  const bottomElementRef = useRef();
  const topGridParentRef = useRef();
  const bottomGridParentRef = useRef();
  const topGridRef = useRef();
  const bottomGridRef = useRef();

  useEffect(() => {
    document.body.classList.add('dashboard');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Ustawianie szerokości gridów bez ich przebudowania
  const resizeHorizontal = useCallback(
    () => {
      document.dispatchEvent(new CustomEvent('gridResize'));
      setTimeout(() => dispatch(gridResize(gridDisplayType)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridDisplayType, leftGridParentRef, rightGridParentRef, dispatch],
  );

  // tylko dla typu drugiego
  const resizeVertical = useCallback(() => {
    setTimeout(() => dispatch(gridResize(gridDisplayType)));
  }, [gridDisplayType, dispatch]);

  return {
    dashboardType,
    setDashboardType,
    rightElementRef,
    leftGridParentRef,
    rightGridParentRef,
    leftGridRef,
    rightGridRef,
    rightDetailRef,
    resizeHorizontal,
    // tylko dla typu drugiego
    bottomElementRef,
    topGridParentRef,
    bottomGridParentRef,
    topGridRef,
    bottomGridRef,
    resizeVertical,
  };
};
