import { createSlice } from '@reduxjs/toolkit';

export const featureFlags = createSlice({
  name: 'featureFlags',
  initialState: {
    flags: [],
  },
  reducers: {
    addFeatureFlags: (state, action) => {
      return { flags: action.payload };
    },
  },
});

export const { addFeatureFlags } = featureFlags.actions;
export const featureFlagsSelector = (state) => state.featureFlags.flags;

export default featureFlags.reducer;
