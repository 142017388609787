import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
  name: 'headerModal',
  initialState: {
    name: null,
    open: false,
    modalProps: {},
  },
  reducers: {
    modalHeaderAction: (state, action) => {
      state.open = action.payload.open;
      state.name = action.payload.name;
      state.modalProps = action.payload.modalProps || {};
    },
  },
});

export const { modalHeaderAction } = modalSlice.actions;

export const modalSelector = (state) => state.headerModal;

export default modalSlice.reducer;
