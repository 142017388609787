import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as endpoint from '../helpers/api/endpoints';

const notificationAdapter = createEntityAdapter({
  selectId: (notification) => notification.id,
  sortComparer: (notifyA, notifyB) => {
    return notifyB.datetime.localeCompare(notifyA.datetime);
  },
});

export const getAgentNotification = createAsyncThunk(
  'notification/agent',
  async (status, { getState, rejectWithValue }) => {
    return await endpoint.getNotificationForAgent(
      (payload) => {
        if (payload.data) {
          return payload.data.map((item) => {
            item['icon'] = item.level === 1 ? 'danger' : item.level === 2 ? 'question' : 'info';
            return item;
          });
        }
        return [];
      },
      (error) => rejectWithValue({ error }),
    );
  },
);

const countUnreadNotify = (state) => {
  const payload = notificationAdapter.getSelectors((state) => state.notifications).selectAll(state);
  return payload?.reduce(
    (accumulator, currentValue) => (currentValue.isRead || currentValue.popup ? 0 : 1) + accumulator,
    0,
  );
};

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: notificationAdapter.getInitialState(),
    unread: 0,
  },
  reducers: {
    notifyUpdateRead: (state, action) => {
      notificationAdapter.updateMany(state.notifications, action.payload);
      state.unread = countUnreadNotify(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAgentNotification.fulfilled, (state, action) => {
      if (action.payload) {
        const currentNotify = notificationAdapter.getSelectors((state) => state.notifications).selectAll(state);
        const payload = action.payload.filter((item) => {
          const current = currentNotify.find((current) => current.id === item.id);
          return !(current && current.isRead !== item.isRead);
        });
        notificationAdapter.upsertMany(state?.notifications, payload);
        state.unread = countUnreadNotify(state);
      }
    });
  },
});

export const { notifyUpdateRead } = notificationSlice.actions;

export const notificationsSelector = () =>
  notificationAdapter.getSelectors((state) => state.notifications.notifications);

export const notifyUnreadSelector = (state) => state.notifications.unread;

export default notificationSlice.reducer;
