import PropTypes from 'prop-types';

export const tagPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.string,
  PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
  PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.shape({
        $$typeof: PropTypes.symbol,
        render: PropTypes.func,
      }),
    ]),
  ),
]);

export const isSafari =
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1;
export const isChrome = navigator.userAgent.indexOf('Chrome') !== -1;

const delay = 300;
let latestClick = {
  time: 0,
  target: null,
};
export const isDblClick = (e) => {
  const click = {
    time: new Date().getTime(),
    target: e.target,
  };
  const isFastDblClick = click.target === latestClick.target && click.time - latestClick.time < delay;
  latestClick = click;
  return isFastDblClick;
};
