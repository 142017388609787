import {
  CATEGORY_FLAT,
  CATEGORY_FLAT_RENT,
  CATEGORY_FLAT_SALE,
  CATEGORY_HOUSE,
  CATEGORY_HOUSE_RENT,
  CATEGORY_HOUSE_SALE,
  CATEGORY_LOCAL,
  CATEGORY_LOCAL_RENT,
  CATEGORY_LOCAL_SALE,
  CATEGORY_OBJECT,
  CATEGORY_OBJECT_RENT,
  CATEGORY_OBJECT_SALE,
  CATEGORY_PLOT,
  CATEGORY_PLOT_RENT,
  CATEGORY_PLOT_SALE,
  CATEGORY_INVESTMENT,
} from 'consts';

export const baseCategory = [
  { id: CATEGORY_HOUSE, name: 'Domy' },
  { id: CATEGORY_PLOT, name: 'Działki' },
  { id: CATEGORY_LOCAL, name: 'Lokale' },
  { id: CATEGORY_FLAT, name: 'Mieszkania' },
  { id: CATEGORY_OBJECT, name: 'Obiekty' },
  { id: CATEGORY_OBJECT, name: 'Obiekty' },
  { id: CATEGORY_INVESTMENT, name: 'Inwestycje' },
];

export const categoryType = {
  [CATEGORY_HOUSE_SALE]: 'house',
  [CATEGORY_HOUSE_RENT]: 'house',
  [CATEGORY_PLOT_SALE]: 'plot',
  [CATEGORY_PLOT_RENT]: 'plot',
  [CATEGORY_LOCAL_SALE]: 'local',
  [CATEGORY_LOCAL_RENT]: 'local',
  [CATEGORY_FLAT_SALE]: 'flat',
  [CATEGORY_FLAT_RENT]: 'flat',
  [CATEGORY_OBJECT_SALE]: 'object',
  [CATEGORY_OBJECT_RENT]: 'object',
  [CATEGORY_INVESTMENT]: 'investment',
};

export const categoryExportNames = {
  Dom: CATEGORY_HOUSE,
  Działki: CATEGORY_PLOT,
  Lokal: CATEGORY_LOCAL,
  Mieszkanie: CATEGORY_FLAT,
  Obiekt: CATEGORY_OBJECT,
  Inwestycja: CATEGORY_INVESTMENT,
};

export const category = [
  { id: CATEGORY_HOUSE_SALE, name: 'Domy Sprzedaż', isRent: false, isSale: true, base: CATEGORY_HOUSE },
  { id: CATEGORY_HOUSE_RENT, name: 'Domy Wynajem', isRent: true, isSale: false, base: CATEGORY_HOUSE },
  { id: CATEGORY_PLOT_SALE, name: 'Działki Sprzedaż', isRent: false, isSale: true, base: CATEGORY_PLOT },
  { id: CATEGORY_PLOT_RENT, name: 'Działki Dzierżawa', isRent: true, isSale: false, base: CATEGORY_PLOT },
  { id: CATEGORY_LOCAL_SALE, name: 'Lokale Sprzedaż', isRent: false, isSale: true, base: CATEGORY_LOCAL },
  { id: CATEGORY_LOCAL_RENT, name: 'Lokale Wynajem', isRent: true, isSale: false, base: CATEGORY_LOCAL },
  { id: CATEGORY_FLAT_SALE, name: 'Mieszkania Sprzedaż', isRent: false, isSale: true, base: CATEGORY_FLAT },
  { id: CATEGORY_FLAT_RENT, name: 'Mieszkania Wynajem', isRent: true, isSale: false, base: CATEGORY_FLAT },
  { id: CATEGORY_OBJECT_SALE, name: 'Obiekty Sprzedaż', isRent: false, isSale: true, base: CATEGORY_OBJECT },
  { id: CATEGORY_OBJECT_RENT, name: 'Obiekty Wynajem', isRent: true, isSale: false, base: CATEGORY_OBJECT },
  { id: CATEGORY_INVESTMENT, name: 'Inwestycje', isRent: false, isSale: false, base: CATEGORY_INVESTMENT },
];
export const neighborCategory = {
  [CATEGORY_HOUSE_SALE]: { id: CATEGORY_HOUSE_RENT, isRent: true },
  [CATEGORY_HOUSE_RENT]: { id: CATEGORY_HOUSE_SALE, isRent: false },
  [CATEGORY_PLOT_SALE]: { id: CATEGORY_PLOT_RENT, isRent: true },
  [CATEGORY_PLOT_RENT]: { id: CATEGORY_PLOT_SALE, isRent: false },
  [CATEGORY_LOCAL_SALE]: { id: CATEGORY_LOCAL_RENT, isRent: true },
  [CATEGORY_LOCAL_RENT]: { id: CATEGORY_LOCAL_SALE, isRent: false },
  [CATEGORY_FLAT_SALE]: { id: CATEGORY_FLAT_RENT, isRent: true },
  [CATEGORY_FLAT_RENT]: { id: CATEGORY_FLAT_SALE, isRent: false },
  [CATEGORY_OBJECT_SALE]: { id: CATEGORY_OBJECT_RENT, isRent: true },
  [CATEGORY_OBJECT_RENT]: { id: CATEGORY_OBJECT_SALE, isRent: false },
  [CATEGORY_INVESTMENT]: { id: CATEGORY_INVESTMENT, isRent: false },
};

export const CATEGORIES_IDS = [
  CATEGORY_HOUSE_SALE,
  CATEGORY_HOUSE_RENT,
  CATEGORY_PLOT_RENT,
  CATEGORY_PLOT_SALE,
  CATEGORY_LOCAL_SALE,
  CATEGORY_LOCAL_RENT,
  CATEGORY_FLAT_SALE,
  CATEGORY_FLAT_RENT,
  CATEGORY_OBJECT_SALE,
  CATEGORY_OBJECT_RENT,
  CATEGORY_INVESTMENT,
];
export const CATEGORIES_OPTIONS = [
  { value: CATEGORY_HOUSE_RENT, label: 'Domy Wynajem' },
  { value: CATEGORY_HOUSE_SALE, label: 'Domy Sprzedaż' },
  { value: CATEGORY_PLOT_SALE, label: 'Działki Sprzedaż' },
  { value: CATEGORY_PLOT_RENT, label: 'Działki Dzierżawa' },
  { value: CATEGORY_FLAT_SALE, label: 'Mieszkanie Sprzedaż' },
  { value: CATEGORY_FLAT_RENT, label: 'Mieszkanie Wynajem' },
  { value: CATEGORY_OBJECT_RENT, label: 'Obiekty Wynajem' },
  { value: CATEGORY_OBJECT_SALE, label: 'Obiekty Sprzedaż' },
  { value: CATEGORY_LOCAL_RENT, label: 'Lokale Wynajem' },
  { value: CATEGORY_LOCAL_SALE, label: 'Lokale Sprzedaż' },
  { value: CATEGORY_INVESTMENT, label: 'Inwestycje' },
];

export const categoriesLabels = {
  [CATEGORY_HOUSE_SALE]: 'Domy Sprzedaż',
  [CATEGORY_HOUSE_RENT]: 'Domy Wynajem',
  [CATEGORY_PLOT_RENT]: 'Działki Dzierżawa',
  [CATEGORY_PLOT_SALE]: 'Działki Sprzedaż',
  [CATEGORY_LOCAL_SALE]: 'Lokale Sprzedaż',
  [CATEGORY_LOCAL_RENT]: 'Lokale Wynajem',
  [CATEGORY_FLAT_SALE]: 'Mieszkanie Sprzedaż',
  [CATEGORY_FLAT_RENT]: 'Mieszkanie Wynajem',
  [CATEGORY_OBJECT_SALE]: 'Obiekty Sprzedaż',
  [CATEGORY_OBJECT_RENT]: 'Obiekty Wynajem',
  [CATEGORY_INVESTMENT]: 'Inwestycje',
};

export const categoriesAbbreviatedLabels = {
  [CATEGORY_HOUSE_SALE]: 'DS',
  [CATEGORY_HOUSE_RENT]: 'DW',
  [CATEGORY_PLOT_RENT]: 'DZW',
  [CATEGORY_PLOT_SALE]: 'DZS',
  [CATEGORY_LOCAL_SALE]: 'LS',
  [CATEGORY_LOCAL_RENT]: 'LW',
  [CATEGORY_FLAT_SALE]: 'MS',
  [CATEGORY_FLAT_RENT]: 'MW',
  [CATEGORY_OBJECT_SALE]: 'OS',
  [CATEGORY_OBJECT_RENT]: 'OW',
  [CATEGORY_INVESTMENT]: 'INW',
};

export const getExportCategory = (name) => {
  return categoryExportNames[name];
};
export const getCategory = (id) => {
  return category.find((c) => c.id === parseInt(id));
};

export const getNeighborCategory = (id) => {
  return neighborCategory[id];
};

export const getStoreCategory = () => {
  return localStorage.getItem('imo-category') || null;
};

export const setStoreCategory = (categoryId) => {
  return localStorage.setItem('imo-category', categoryId);
};
