import { notEmpty } from '../../helpers/utils';
import deepFilter from 'deep-filter';

const normalizeKeys = ['property', 'question'];
export const normalizerFromApi = async (data) => {
  for (const item of normalizeKeys) {
    if (data?.[item]?.street) {
      data[item].street = streetNormalize(data[item].street);
    }
    if (data?.[item]?.location) {
      data[item].location = locationNormalize(data[item].location);
    }
    if (data?.[item]?.exports) {
      data[item].exports = data[item].exports.reduce((acc, i) => {
        acc[`_${i}`] = true;
        return acc;
      }, {});
    }
    if (data?.[item]?.addingDate?.from) {
      data[item].addingDate.from = dateNormalize(data[item].addingDate.from);
    }
    if (data?.[item]?.addingDate?.to) {
      data[item].addingDate.to = dateNormalize(data[item].addingDate.to);
    }
    if (data?.[item]?.updateDate?.from) {
      data[item].updateDate.from = dateNormalize(data[item].updateDate.from);
    }
    if (data?.[item]?.updateDate?.to) {
      data[item].updateDate.to = dateNormalize(data[item].updateDate.to);
    }
    if (data?.[item]?.contractDate?.start) {
      data[item].contractDate.start = dateNormalize(data[item].contractDate.start);
    }
    if (data?.[item]?.contractDate?.end) {
      data[item].contractDate.end = dateNormalize(data[item].contractDate.end);
    }
  }

  return data;
};

export const normalizerToApi = (data) => {
  const normalizePrice = (item) => {
    if (data?.[item]?.price) {
      Object.entries(data[item].price).forEach(([key, value]) => {
        if (!notEmpty(value?.from) && !notEmpty(value?.to)) {
          delete data[item].price[key];
        }
      });
    }
  };

  const normalizeLocation = (item) => {
    if (data?.[item]?.location) {
      data[item].location = data[item].location.map((id) => id.selected);
    }
  };

  const normalizeStreet = (item) => {
    if (data?.[item]?.street) {
      data[item].street = data[item].street.map((id) => id.value);
    }
  };

  normalizeKeys.forEach((item) => {
    normalizePrice(item);
    normalizeStreet(item);
    normalizeLocation(item);
  });

  if (data?.property?.exports) {
    data.property.exports = Object.entries(data.property.exports).map(([key, value]) => {
      return value === true ? parseInt(key.replace('_', '')) : undefined;
    });
  }

  return deepFilter(data, notEmpty);
};

export const locationNormalize = (locations) =>
  locations?.map((location) => ({
    value: location.level + '_' + location.id,
    selected: {
      level: location.level,
      id: location.id,
      labelParts: {
        name: location.name,
        parents: location.parents,
      },
    },
    label: location.name + ', ' + location.parents,
  }));

export const streetNormalize = (streets) =>
  streets?.map((street) => ({
    value: street.street_id,
    label: street.street_name,
  }));

const dateNormalize = (date) => {
  return date.split('-').reverse().join('-');
};
