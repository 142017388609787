import logo from '../assets/img/splashscreen.svg';

const SplashScreen = () => {
  return (
    <div className="pt-3 text-center animation-gradient">
      <div className="waviy">
        <span style={{ color: '#fff' }}>Wczytywanie...</span>
      </div>
      <img className="splash-logo" src={logo} alt="IMO" />
    </div>
  );
};

export default SplashScreen;
